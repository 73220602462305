<template>
    <div class="skeleton-container">
        <v-skeleton-loader type="image" height="76" width="215" />
        <v-skeleton-loader type="image" height="76" width="215" />
        <v-skeleton-loader type="image" height="76" width="215" />
    </div>
</template>

<script>
    export default {
        name: 'SkeletonCommissionSet'
    };
</script>

<style scoped lang="scss">
    .skeleton-container {
        display: flex;

        .v-skeleton-loader {
            border-radius: spacing.$spacing-2;

            &:not(:first-child) {
                margin-left: spacing.$spacing-2;
            }
        }
    }
</style>
