<template>
    <div>
        <v-data-table
            class="app-table"
            hide-default-footer
            multi-sort
            :loading="loading"
            :items="items"
            :headers="headers"
            :options.sync="options"
            :server-items-length="items.length"
        >
            <template #item.productId="{ item }">
                {{ $t('productTypes.' + item.productId) }}
            </template>

            <template #item.openedAt="{ item }">
                {{ formatTimestamp(item.openedAt) }}
            </template>

            <template #item.activationDate="{ item }">
                {{ formatTimestamp(item.activatedAt) }}
            </template>

            <template #item.productAccountId="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:contracts:detail',
                        params: { id: item.productAccountId, return: { name: 'account:commission:set' } }
                    }"
                >
                    {{ item.productAccountId }}
                </router-link>
            </template>

            <template #item.client="{ item }">
                <span v-if="item.client">{{ getClientName(item.client) }}</span>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template #item.referral="{ item }">
                {{ getReferralName(item) }}
            </template>

            <template #item.actualShare="{ item }">
                <span v-if="item.actualShare"> {{ item.actualShare }}% </span>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template #item.amount="{ item }">
                {{ formatAmount(item.amount, item.currency) }} {{ item.currency }}
            </template>

            <template #loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template #no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination, sorting } from '@lb-world/core/public/components/tables/mixins';
    import { mapGetters } from 'vuex';

    import { createHeaderItem } from '@lb-world/core/public/components/tables/utils';

    export default {
        name: 'CommissionSetTable',

        mixins: [core, filtering, pagination, sorting],

        methods: {
            getClientName(client) {
                const name = client?.name;
                const surname = client?.surname;

                return name && surname ? name + ' ' + surname : '-';
            },

            getReferralName(item) {
                const referral = item.client?.referral;

                if (referral) {
                    return referral.name + ' ' + referral.surname;
                } else {
                    return '-';
                }
            }
        },

        computed: {
            ...mapGetters('clients', { treeReferrals: 'tree:selectBox' }),

            headers() {
                return [
                    createHeaderItem(this.$t('table.columns.productType'), 'productId', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.commission'), 'amount', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.productAccountId'), 'productAccountId', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.client'), 'client'),
                    createHeaderItem(this.$t('table.columns.referral'), 'referral'),
                    createHeaderItem(this.$t('table.columns.agreementDate'), 'openedAt'),
                    createHeaderItem(this.$t('table.columns.activationDate'), 'activationDate'),
                    createHeaderItem(this.$t('table.columns.actualShare'), 'actualShare', { sortable: true })
                ];
            }
        }
    };
</script>

<style scoped></style>
