<template>
    <app-row>
        <app-registration-col whole-screen>
            <ul>
                <li>
                    Veškeré pohledávky 4Trans za odběrateli jsou pojištěné u renomované nadnárodní pojišťovny Euler
                    Hermes (člen rodiny Allianz)
                </li>
                <li>Růst poptávky po dopravních službách tažený růstem e-commerce</li>
                <li>Propracované technologické řešení 4Trans</li>
                <li>Propracovaný risk management</li>
                <li>Rychlost profinancování</li>
                <li>Nadstavbové služby</li>
            </ul>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'Advantage4Trans'
    };
</script>

<style scoped></style>
