<template>
    <app-container unlimited-width decreased-page-padding>
        <user-referral-overview />

        <app-divider />

        <news-overview />

        <loaded-commission-overview commission-redirect />

        <expiring-contracts-overview />
    </app-container>
</template>

<script>
    import UserReferralOverview from '@/components/module-user/referralOverview/UserReferralOverview';
    import NewsOverview from '@/components/module-user/dashboard/NewsOverview';
    import LoadedCommissionOverview from '@/components/module-user/commissions/LoadedCommissionOverview';
    import ExpiringContractsOverview from '@/components/module-user/dashboard/ExpiringContractsOverview';

    export default {
        name: 'Dashboard',

        components: {
            ExpiringContractsOverview,
            LoadedCommissionOverview,
            NewsOverview,
            UserReferralOverview
        }
    };
</script>

<style scoped></style>
