import Vue from 'vue';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import TableDataset from '@lb-world/core/public/models/store/TableDataset';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import CARepository from '@lb-world/core/public/api/repositories/CARepository';

const state = {
    accounts: new BaseDataset(),
    transactions: new TableDataset()
};

const getters = {
    accountsBalances: state => state.accounts.data?.balances,
    accountsFetched: state => state.accounts.fetched,
    accountsError: state => state.accounts.fetchError,

    transactions: state => state.transactions.data?.items ?? [],
    transactionsFetched: state => state.transactions.fetched,
    transactionsError: state => state.transactions.fetchError,
    transactionsPages: state => state.transactions.getPages()
};

const actions = {
    'accounts:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.get(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'accounts',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'accounts');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'accounts');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'accounts:detail:fetch': (_, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.get(userId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'accounts:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'accounts');

            resolve();
        });
    },

    payout: ({ commit, rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.payout(rootGetters['user/user:id'], data)
                .then(response => {
                    const commissionAccount = response.data;

                    Vue.$log.debug('[ACTION] Received response', commissionAccount);

                    if (commissionAccount) {
                        commit('store', {
                            key: 'accounts',
                            data: response.data
                        });

                        resolve(commissionAccount);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'transactions:fetch': ({ commit, rootGetters }, { page }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.getTransactions({ data: { userId: rootGetters['user/user:id'] }, apiOptions: { page } })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'transactions',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'transactions');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'transactions');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'transactions:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'transactions');

            resolve();
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
