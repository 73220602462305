<template>
    <validation-observer ref="formProvisionPayoutDialog">
        <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title :title="$t('bo.commission.payout.dialog.title')" :dialog="dialog" />

                <app-card>
                    <app-row justify="center">
                        <app-registration-col field>
                            <p>
                                {{ $t('bo.commission.payout.dialog.balanceAvailable') }}
                            </p>
                            <p>
                                <mark>{{ formatAmount(commissionEFKBalance, 'efk') }} EFK</mark>
                            </p>
                        </app-registration-col>
                    </app-row>

                    <app-row justify="center" class="mt-6">
                        <app-registration-col field>
                            <app-text-field
                                :rules="{
                                    required: true,
                                    regex: numberRegex,
                                    max_value: [commissionEFKBalance, 'efk'],
                                    min_value: 0.0001,
                                    decimals: 4
                                }"
                                v-model="provisionData.amount"
                                :label="$t('bo.commission.payout.dialog.payoutAmount')"
                            >
                                <template #append>
                                    EFK
                                </template>
                            </app-text-field>
                        </app-registration-col>
                    </app-row>

                    <app-divider />

                    <app-text justify="center">
                        {{ $t('bo.commission.payout.dialog.payoutDeliveringDescription') }}
                    </app-text>

                    <app-row justify="center">
                        <app-registration-col field class="pt-0">
                            <app-logo justify="center" size="sm" logo-id="broker" />
                        </app-registration-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <button-primary full-width @click="submit" :loading="submitButtonLoading">
                        {{ $t('bo.commission.payout.dialog.payout') }}
                    </button-primary>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'CommissionPayoutDialog',

        components: { AppTextField, ValidationObserver },

        props: ['dialog'],

        data: () => ({
            submitButtonLoading: false,

            commissionEFKBalance: null,

            provisionData: {
                currency: '',
                amount: ''
            }
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            }
        },

        methods: {
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formProvisionPayoutDialog
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('commission/payout', {
                                    currency: this.provisionData.currency,
                                    amount: this.convertLocaleToNumber(this.provisionData.amount)
                                })
                                .then(() => {
                                    this.dialog.open = false;

                                    EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                                    this.$emit('payout:success');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.provisionData.amount = '';
                        this.provisionData.currency = 'EFK';

                        this.commissionEFKBalance = this.dialog?.params?.balance ?? 0;

                        if (this.$refs.formProvisionPayoutDialog) {
                            this.$refs.formProvisionPayoutDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
