<template>
    <div>
        <table-filter-bar v-on="$listeners" custom-default-visible>
            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.limitTo')"
                        :items="commissionTypes"
                        v-model="filters.limitTo"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.referralId')"
                        :items="treeReferrals"
                        :loading="!treeFetched"
                        v-model="filters.referralId"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthFrom')"
                        :menu.sync="calendar.monthFrom"
                        v-model="filters.monthFrom"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthTo')"
                        :menu.sync="calendar.monthTo"
                        v-model="filters.monthTo"
                    />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
            :options.sync="options"
            multi-sort
        >
            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template #item.referral="{ item }">
                {{ item.client.name + ' ' + item.client.surname }}
            </template>

            <template #item.createdAt="{ item }">
                {{ formatTimestamp(item.createdAt) }}
            </template>

            <template #item.amount="{ item }">
                {{ formatAmount(item.amount, item.currency) }}
            </template>

            <template #item.type="{ item }"> {{ $t('transactionTypes.' + item.type) }} </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, pagination, filtering, sorting } from '@lb-world/core/public/components/tables/mixins';
    import { PTFactory } from '@lb-world/core/public/static/productTypes';
    import { mapGetters } from 'vuex';

    import { createHeaderItem } from '@lb-world/core/public/components/tables/utils';

    import AppSelectField from '@/components/ui/form/AppSelectField';
    import Currencies from '@lb-world/core/public/utils/currencies';
    import AppDatePicker from '@/components/ui/form/AppDatePicker';

    export default {
        name: 'ContractDetailCommissionsTable',

        components: { AppDatePicker, AppSelectField },

        mixins: [core, pagination, filtering, sorting],

        data: () => ({
            productTypes: PTFactory.getSalesmanCommission(),

            calendar: {
                monthFrom: false,
                monthTo: false
            },

            commissionTypes: [
                { name: 'bo.commissionTypes.own', value: 'own' },
                { name: 'bo.commissionTypes.team', value: 'team' },
                { name: 'bo.commissionTypes.total', value: 'total' }
            ]
        }),

        computed: {
            ...mapGetters('clients', {
                treeFetched: 'tree:fetched',
                treeData: 'tree:children',
                treeError: 'tree:error',
                treeReferrals: 'tree:selectBox'
            }),

            currencies() {
                return Currencies.getCurrencies('commissionPayout');
            },

            headers() {
                return [
                    createHeaderItem(this.$t('table.columns.id'), 'id'),
                    createHeaderItem(this.$t('table.columns.referral'), 'referral'),
                    createHeaderItem(this.$t('table.columns.createdAt'), 'createdAt', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.amount'), 'amount', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.currency'), 'currency', { sortable: true })
                ];
            }
        }
    };
</script>

<style scoped></style>
