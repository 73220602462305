import axios from 'axios';

import Vue from 'vue';

import {
    API_SERVER_ERROR,
    API_INTERNAL_ERROR,
    parseErrorMessageFromAPI,
    JWTRefreshOnExpiration,
    JWTDecode,
    getWorldAppUrl,
    getAuthApiUrl
} from '@/utils';

import AuthRepository from '@lb-world/core/public/api/repositories/AuthRepository';
import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

import { UserRoles } from '@lb-world/core/public/static/roles';

const state = {
    token: '',
    tokenFetched: false,
    decodedToken: {}
};

const getters = {
    token: state => state.token,
    'token:fetched': state => state.tokenFetched,
    'token:id': state => state.decodedToken.id,
    'token:namespace': state => state.decodedToken.namespace,
    'token:role': state => role => UserRoles.hasRole(state.decodedToken, role)
};

const actions = {
    'user:login': (_, { email, password, namespace, rememberMe = false }) => {
        Vue.$log.debug('[ACTION] Running action with API call', email, password, namespace, rememberMe);

        let data = { email, password, namespace, rememberMe };
        data.continue = getWorldAppUrl();

        return new Promise((resolve, reject) => {
            AuthRepository.login(data)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const redirect = response.data.redirect;

                    if (redirect) {
                        resolve(redirect);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('user/clear', null, { root: true });
            commit('user:logout');

            resolve();
        });
    },
    'user:fetch': ({ commit, getters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            UserRepository.get(getters['token:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const user = response.data;

                    if (user) {
                        commit('user/store', user, { root: true });

                        resolve(user);
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:logout': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AuthRepository.logout()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    commit('user:logout');

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'token:refresh': (_, expire) => {
        Vue.$log.debug('[ACTION] Running action with API call', expire);

        return new Promise((resolve, reject) => {
            if (expire) {
                JWTRefreshOnExpiration(expire);

                resolve();
            } else {
                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'token:store': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AuthRepository.checkToken(getAuthApiUrl())
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const token = response.data.accessToken;
                    const expiration = response.data.expiration;

                    const decodedToken = JWTDecode(token);

                    if (token && decodedToken) {
                        commit('token:store', { token, decodedToken });

                        resolve({ token, expiration, decodedToken });
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject({
                        error: parseErrorMessageFromAPI(error),
                        statusCode: error?.status
                    });
                })
                .finally(() => {
                    commit('token:fetched');
                });
        });
    }
};

const mutations = {
    'token:fetched': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.tokenFetched = true;
    },
    'token:store': (state, { token, decodedToken }) => {
        Vue.$log.debug('[MUTATION] Running mutation', token, decodedToken);

        state.token = token;
        state.decodedToken = decodedToken;

        axios.defaults.headers.common['Authorization'] = token;
    },
    'user:logout': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.user = {};

        state.decodedToken = {};
        state.token = '';

        delete axios.defaults.headers.common['Authorization'];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
