<template>
    <app-row>
        <app-registration-col whole-screen>
            <v-simple-table>
                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">Délka trvání</span></td>
                        <td>3 až 5 let</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Minimální kupní cena</span></td>
                        <td>100 000 EFK</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Úroková sazba</span></td>
                        <td>7.1 % p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Fixní směnný kurz</span></td>
                        <td>0,85 CZK/EFK</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Výplata úroků</span></td>
                        <td>kvartálně</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Upisovací období produktu</span></td>
                        <td>45 dnů</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Maximální objem tokenů</span></td>
                        <td>50 000 000</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Maximální objednávka od klienta</span></td>
                        <td>Polovina EFK tokenů v držení investora ke 3.8.2020</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'ParametersDigiRent'
    };
</script>

<style scoped></style>
