import { render, staticRenderFns } from "./NewsOverview.vue?vue&type=template&id=6ddd9a63&scoped=true&"
import script from "./NewsOverview.vue?vue&type=script&lang=js&"
export * from "./NewsOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ddd9a63",
  null
  
)

export default component.exports