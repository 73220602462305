import Vue from 'vue';

import { API_SERVER_ERROR } from '@lb-world/core/public/api/utils';

import { parseErrorMessageFromAPI } from '@/utils';

import DocumentsRepository from '@lb-world/core/public/api/repositories/DocumentsRepository';

const actions = {
    download: (_, documentId) => {
        Vue.$log.debug('[ACTION] Running action with API call', documentId);

        return new Promise((resolve, reject) => {
            DocumentsRepository.createDownload(documentId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data && response.data.url) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

export default {
    namespaced: true,
    mutations: {},
    actions,
    getters: {},
    state: {}
};
