<template>
    <app-row>
        <app-registration-col whole-screen>
            <ul>
                <li>Snadná směna EFK tokenu bez nutnosti registrace na jednotlivé burzy</li>
                <li>Využití robotického obchodování pro maximalizaci hodnoty směny</li>
                <li>Možnost prodloužení produktu</li>
            </ul>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'AdvantageEfkLiquidity'
    };
</script>

<style scoped></style>
