<template>
    <app-row class="mt-3">
        <app-registration-col field>
            <app-text-stack>
                <template #key>
                    {{ $t('bo.contracts.detail.minSaleRate') }}
                </template>

                {{ contract.getMinSaleRate() }}
            </app-text-stack>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'CDetailEFKLiquidity',

        props: ['contract']
    };
</script>

<style scoped></style>
