<template>
    <app-container unlimited-width decreased-page-padding>
        <app-detail-header icon="handshake-alt" :on-back-pressed="onBackPressed">
            {{ contractId }}
        </app-detail-header>

        <app-row>
            <app-registration-col whole-screen>
                <v-expansion-panels v-model="openedPanels">
                    <app-expansion-panel
                        :title="$t('bo.contracts.detail.detailTitle')"
                        :loading="!contract.fetched"
                        icon="file-alt"
                    >
                        <app-loader v-if="!contract.fetched" wrapper />

                        <div v-else>
                            <app-row>
                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.productType') }}
                                        </template>

                                        {{ $t(contract.getProductType()) }}
                                    </app-text-stack>
                                </app-registration-col>

                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.activationDate') }}
                                        </template>

                                        {{ contract.getActivationDate() }}
                                    </app-text-stack>
                                </app-registration-col>
                            </app-row>

                            <app-row class="mt-3">
                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.agreementDate') }}
                                        </template>

                                        {{ contract.getOpenedDate() }}
                                    </app-text-stack>
                                </app-registration-col>

                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.amount') }}
                                        </template>

                                        {{ contract.getAmount(true) }}
                                    </app-text-stack>
                                </app-registration-col>
                            </app-row>

                            <component
                                v-if="allowedProductsDetails.includes(contract.getProductType(true))"
                                :is="contract.getProductType(true)"
                                :contract="contract"
                            />

                            <app-divider :bottom-margin="0" />

                            <app-row class="mb-3">
                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.referral') }}
                                        </template>

                                        <app-loader v-if="!client.fetched" />
                                        <span v-else>{{ client.getReferralFullName() }}</span>
                                    </app-text-stack>
                                </app-registration-col>

                                <app-registration-col field>
                                    <app-text-stack>
                                        <template #key>
                                            {{ $t('bo.contracts.detail.approvedLimit') }}
                                        </template>

                                        <app-loader v-if="!clientAML" />
                                        <span v-else>{{ clientLimit }}</span>
                                    </app-text-stack>
                                </app-registration-col>
                            </app-row>
                        </div>
                    </app-expansion-panel>

                    <app-expansion-panel
                        :title="$t('bo.contracts.detail.client.title')"
                        :loading="!client.fetched"
                        :additional-data="client.getFullName()"
                        icon="user-alt"
                    >
                        <app-row>
                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('bo.contracts.detail.client.nameAndSurname') }}
                                    </template>

                                    <router-link
                                        class="detail"
                                        :to="{
                                            name: 'account:clients:detail',
                                            params: {
                                                id: client.getId(),
                                                return: { name: 'account:contracts:detail', params: { id: contractId } }
                                            }
                                        }"
                                    >
                                        {{ client.getFullName() }}
                                    </router-link>
                                </app-text-stack>
                            </app-registration-col>

                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('bo.contracts.detail.client.email') }}
                                    </template>

                                    {{ client.getEmail() }}
                                </app-text-stack>
                            </app-registration-col>
                        </app-row>

                        <app-row class="mt-3">
                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('address.streetLine', { number: 1 }) }}
                                    </template>

                                    {{ client.address.getLine1() }}
                                </app-text-stack>
                            </app-registration-col>

                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('address.streetLine', { number: 2 }) }}
                                    </template>

                                    {{ client.address.getLine2() }}
                                </app-text-stack>
                            </app-registration-col>
                        </app-row>

                        <app-row class="mt-3">
                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('address.city') }}
                                    </template>

                                    {{ client.address.getCity() }}
                                </app-text-stack>
                            </app-registration-col>

                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('address.country') }}
                                    </template>

                                    {{ client.address.getCountry() }}
                                </app-text-stack>
                            </app-registration-col>
                        </app-row>

                        <app-row class="mt-3">
                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('address.postalCode') }}
                                    </template>

                                    {{ client.address.getPostalCode() }}
                                </app-text-stack>
                            </app-registration-col>

                            <app-registration-col field>
                                <app-text-stack>
                                    <template #key>
                                        {{ $t('bo.contracts.detail.client.phone') }}
                                    </template>

                                    {{ client.phone.getPhone() }}
                                </app-text-stack>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>
            </app-registration-col>
        </app-row>

        <app-title class="mt-6">{{ $t('bo.contracts.detail.historicalTransactions') }}</app-title>

        <app-row>
            <app-registration-col whole-screen class="pb-0">
                <v-expansion-panels>
                    <app-expansion-panel :title="$t('bo.contracts.detail.export.title')" additional-data="CSV, PDF">
                        <app-text>
                            <span v-html="$t('bo.contracts.detail.export.description')" />
                        </app-text>

                        <app-row>
                            <app-registration-col whole-screen class="pa-0">
                                <app-flex>
                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="transactionsExport('csv')"
                                            :loading="exportButtonLoading['transactions']['csv']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'CSV' }) }}
                                        </button-secondary>
                                    </app-flex-item>

                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="transactionsExport('pdf')"
                                            :loading="exportButtonLoading['transactions']['pdf']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'PDF' }) }}
                                        </button-secondary>
                                    </app-flex-item>
                                </app-flex>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>

                <app-card>
                    <contract-detail-transactions-table
                        :loading="!historicalTransactions.fetched"
                        :fetch-error="historicalTransactions.fetchError"
                        :items="historicalTransactions.getItems()"
                        :pages="historicalTransactions.getPages()"
                        @pagination:change="fetchTransactionsHistory"
                        @sort:update="sortTransactionsHistory"
                    />
                </app-card>
            </app-registration-col>
        </app-row>

        <app-title class="mt-6">{{ $t('bo.contracts.detail.commissionTransactions') }}</app-title>

        <app-row>
            <app-registration-col whole-screen class="pb-0">
                <v-expansion-panels>
                    <app-expansion-panel :title="$t('bo.contracts.detail.export.title')" additional-data="CSV, PDF">
                        <app-text>
                            <span v-html="$t('bo.contracts.detail.export.description')" />
                        </app-text>

                        <app-row>
                            <app-registration-col whole-screen class="pa-0">
                                <app-flex>
                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="commissionExport('csv')"
                                            :loading="exportButtonLoading['commission']['csv']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'CSV' }) }}
                                        </button-secondary>
                                    </app-flex-item>

                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="commissionExport('pdf')"
                                            :loading="exportButtonLoading['commission']['pdf']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'PDF' }) }}
                                        </button-secondary>
                                    </app-flex-item>
                                </app-flex>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>

                <app-card>
                    <contract-detail-commissions-table
                        :pre-applied="commissionTransactionsTable.preAppliedFilters"
                        :filters.sync="commissionTransactionsTable.filters"
                        :loading="!commissionTransactions.fetched"
                        :fetch-error="commissionTransactions.fetchError"
                        :custom-default="commissionTransactionsTable.customDefault"
                        :items="commissionTransactions.getItems()"
                        :pages="commissionTransactions.getPages()"
                        @pagination:change="fetchCommissionTransactions"
                        @filter:update="filterCommissionTransactions"
                        @filter:reset="filterCommissionTransactions"
                        @sort:update="sortCommissionTransactions"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import Contract from '@lb-world/core/public/models/Contract';

    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';
    import CDetailEFKStake from '@/components/module-user/contracts/CDetailEFKStake';
    import ContractDetailTransactionsTable from '@/components/module-user/contracts/ContractDetailTransactionsTable';
    import ContractDetailCommissionsTable from '@/components/module-user/contracts/ContractDetailCommissionsTable';
    import CDetailEFKLiquidity from '@/components/module-user/contracts/CDetailEFKLiquidity';
    import CDetailEFKPurchase from '@/components/module-user/contracts/CDetailEFKPurchase';

    import TableDataset from '@lb-world/core/public/models/store/TableDataset';
    import UserDataset from '@lb-world/core/public/models/store/UserDataset';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppDetailHeader from '@lb-world/core/public/components/utils/AppDetailHeader';

    export default {
        name: 'ContractDetail',

        components: {
            AppDetailHeader,
            AppFlexItem,
            AppFlex,
            ContractDetailTransactionsTable,
            ContractDetailCommissionsTable,
            AppExpansionPanel,

            EFK_STAKE: CDetailEFKStake,
            EFK_LIQUIDITY__VALUE: CDetailEFKLiquidity,
            EFK_PURCHASE: CDetailEFKPurchase
        },

        data: () => ({
            openedPanels: 0,

            allowedProductsDetails: ['EFK_STAKE', 'EFK_LIQUIDITY__VALUE', 'EFK_PURCHASE'],

            contractId: null,

            clientAML: null,
            contract: new Contract(),
            client: new UserDataset(),

            historicalTransactions: new TableDataset(),
            historicalTransactionsTable: {
                page: 1,
                sorts: null
            },

            commissionTransactions: new TableDataset(),
            commissionTransactionsTable: {
                page: 1,
                sorts: null,
                preAppliedFilters: {
                    limitTo: ''
                },
                customDefault: {
                    limitTo: 'total'
                },
                filters: {
                    limitTo: '',
                    referralId: '',
                    monthFrom: '',
                    monthTo: ''
                },
                testObject: {}
            },

            exportButtonLoading: {
                transactions: { csv: false, pdf: false },
                commission: { csv: false, pdf: false }
            }
        }),

        watch: {
            $route() {
                this.contractId = this.$route.params?.id;

                this.fetchContractDetail();
            }
        },

        computed: {
            clientLimit() {
                const limits = [3, 2, 1];

                for (const limit in limits) {
                    if (this.clientAML?.['limit' + limit]?.approved) {
                        return 'Limit ' + limit;
                    }
                }

                return this.$t('common.none');
            }
        },

        methods: {
            downloadCSVData(data, exportFilename) {
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + escape(data);
                anchor.target = '_blank';
                anchor.download = exportFilename;
                anchor.click();
            },

            downloadBinaryData(data, filename) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
            },

            commissionExport(format) {
                this.exportButtonLoading['commission'][format] = true;

                this.$store
                    .dispatch('contracts/detail:commission:export', {
                        productAccountId: this.contractId,
                        filters: this.commissionTransactionsTable.filters,
                        sorts: this.commissionTransactionsTable.sorts,
                        exportTo: format,
                        binary: format === 'pdf'
                    })
                    .then(({ data, filename }) => {
                        if (format === 'pdf') {
                            this.downloadBinaryData(data, filename);
                        } else {
                            this.downloadCSVData(data, filename);
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.exportButtonLoading['commission'][format] = false;
                    });
            },

            transactionsExport(format) {
                this.exportButtonLoading['transactions'][format] = true;

                this.$store
                    .dispatch('contracts/detail:transactions:export', {
                        productAccountId: this.contractId,
                        exportTo: format,
                        sorts: this.historicalTransactionsTable.sorts,
                        binary: format === 'pdf'
                    })
                    .then(({ data, filename }) => {
                        if (format === 'pdf') {
                            this.downloadBinaryData(data, filename);
                        } else {
                            this.downloadCSVData(data, filename);
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.exportButtonLoading['transactions'][format] = false;
                    });
            },

            onBackPressed() {
                const returnRoute = this.$route.params?.return;

                this.$router.push(returnRoute ? returnRoute : { name: 'account:contracts' });
            },

            fetchContractDetail() {
                this.$store.dispatch('contracts/detail:contract:fetch', this.contractId).then(contract => {
                    this.contract.storeData(contract);

                    this.fetchContractClient(contract.userId);
                    this.fetchContractAML(contract.userId);
                });
            },

            fetchContractClient(userId) {
                this.$store.dispatch('clients/detail:fetch', userId).then(client => {
                    this.client.storeData(client);
                });
            },

            fetchContractAML(userId) {
                this.$store.dispatch('clients/detail:aml:fetch', userId).then(aml => {
                    this.clientAML = aml;
                });
            },

            fetchTransactionsHistory(page) {
                this.historicalTransactions.clearData();
                this.historicalTransactionsTable.page = page;

                this.$store
                    .dispatch('contracts/detail:transactions:fetch', {
                        productAccountId: this.contractId,
                        sorts: this.historicalTransactionsTable.sorts,
                        page: this.historicalTransactionsTable.page
                    })
                    .then(transactions => {
                        this.historicalTransactions.storeData(transactions);
                    });
            },

            sortTransactionsHistory(sorts) {
                this.historicalTransactionsTable.sorts = sorts;

                this.fetchTransactionsHistory(1);
            },

            fetchCommissionTransactions(page) {
                this.commissionTransactions.clearData();
                this.commissionTransactionsTable.page = page;

                this.$store
                    .dispatch('contracts/detail:commission:fetch', {
                        productAccountId: this.contractId,
                        page: this.commissionTransactionsTable.page,
                        sorts: this.commissionTransactionsTable.sorts,
                        filters: this.commissionTransactionsTable.filters
                    })
                    .then(transactions => {
                        this.commissionTransactions.storeData(transactions);
                    });
            },

            filterCommissionTransactions(filters) {
                this.commissionTransactions.clearData();
                this.commissionTransactionsTable.page = 1;
                this.commissionTransactionsTable.filters = this.deepCopy(filters);

                this.$store
                    .dispatch('contracts/detail:commission:fetch', {
                        productAccountId: this.contractId,
                        page: this.commissionTransactionsTable.page,
                        sorts: this.commissionTransactionsTable.sorts,
                        filters: this.commissionTransactionsTable.filters
                    })
                    .then(transactions => {
                        this.commissionTransactions.storeData(transactions);
                    });
            },

            sortCommissionTransactions(sorts) {
                this.commissionTransactionsTable.sorts = sorts;

                this.fetchCommissionTransactions(1);
            }
        },

        mounted() {
            if (!this.treeFetched) {
                this.$store.dispatch('clients/tree:fetch');
            }

            const routeFilters = this.$route.params.filters;

            for (const filter in routeFilters) {
                this.$set(this.commissionTransactionsTable.preAppliedFilters, filter, routeFilters[filter]);
                this.$set(this.commissionTransactionsTable.filters, filter, routeFilters[filter]);
            }

            if (!(routeFilters && routeFilters['limitTo'])) {
                this.commissionTransactionsTable.filters['limitTo'] = 'total';
                this.commissionTransactionsTable.preAppliedFilters['limitTo'] = 'total';
            }

            this.contractId = this.$route.params?.id;

            this.fetchContractDetail();
            this.fetchTransactionsHistory();
            this.fetchCommissionTransactions();
        }
    };
</script>

<style scoped lang="scss"></style>
