<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                LB Stake EFK je určený občanům a ke zhodnocení jednorázově vložených EFK tokenů, které jsou úročeny.
            </p>

            <p class="mt-6">
                Délka trvání vkladu je od šesti měsíců a vklad je veden v EFK tokenech.
            </p>

            <p class="mt-6">
                Stake EFK lze ovládat přes prostředí LBworld v sekci LBbroker (výběry a nastavení automatické obnovy).
            </p>

            <p class="mt-6">
                Úrokové sazby jsou závislé na délce trvání vkladu a objemu vložených prostředků.
            </p>

            <p class="mt-6">
                Úroky z vkladu se automaticky načítají na peněženku Klienta u LBbroker. U vkladu dochází k automatické
                obnově. Úroky jsou připisovány měsíčně.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'IntroductionEfkStake'
    };
</script>

<style scoped></style>
