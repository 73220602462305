<template>
    <app-container decreased-page-padding unlimited-width>
        <app-row>
            <app-registration-col whole-screen>
                <app-card table left>
                    <clients-table
                        :filters.sync="clientsTable.filters"
                        :items="customers"
                        :loading="!customersFetched"
                        :pages="customersPages"
                        :fetch-error="customersError"
                        :pre-applied="clientsTable.preAppliedFilters"
                        @pagination:change="fetchClients"
                        @filter:update="filterClients"
                        @filter:reset="filterClients"
                        @sort:update="sortClients"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import ClientsTable from '@/components/ui/tables/ClientsTable';

    export default {
        name: 'Clients',

        components: {
            ClientsTable
        },

        data: () => ({
            clientsTable: {
                page: 1,
                sorts: null,
                preAppliedFilters: null,
                filters: {
                    fullname: '',
                    referralId: '',
                    personalNumber: '',
                    city: '',
                    monthFrom: '',
                    monthTo: ''
                }
            }
        }),

        computed: mapGetters('clients', {
            customers: 'list',
            customersFetched: 'list:fetched',
            customersError: 'list:error',
            customersPages: 'list:pages',
            customersFilter: 'list:filter'
        }),

        methods: {
            fetchClients(page) {
                this.clientsTable.page = page;

                this.$store.dispatch('clients/list:clear').then(() => {
                    this.$store
                        .dispatch('clients/list:fetch', {
                            page,
                            sorts: this.clientsTable.sorts,
                            filter: this.clientsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            filterClients(filters) {
                this.clientsTable.filters = filters;
                this.clientsTable.page = 1;

                this.$store.dispatch('clients/list:clear').then(() => {
                    this.$store
                        .dispatch('clients/list:fetch', {
                            page: this.clientsTable.page,
                            sorts: this.clientsTable.sorts,
                            filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            sortClients(sorts) {
                this.clientsTable.sorts = sorts;

                this.fetchClients(1);
            }
        },

        mounted() {
            if (this.customersFilter) {
                this.$set(this.clientsTable, 'preAppliedFilters', this.customersFilter);
            }

            if (!this.customersFetched) {
                this.fetchClients();
            }
        }
    };
</script>

<style scoped></style>
