const state = {
    accountTabs: {
        type: 'selection',
        i18nPrefix: 'bo.menu',
        data: [
            {
                name: 'dashboard',
                path: 'account:dashboard',
                icon: 'th'
            },
            {
                name: 'commission',
                path: 'account:commission',
                icon: 'coin',
                children: ['account:commission:transactions', 'account:commission:set']
            },
            {
                name: 'coworkers',
                path: 'account:coworkers',
                icon: 'user-tie'
            },
            {
                name: 'clients',
                path: 'account:clients',
                icon: 'users',
                children: ['account:clients:detail']
            },
            {
                name: 'contracts',
                path: 'account:contracts',
                icon: 'handshake-alt',
                children: ['account:contracts:detail']
            },
            {
                name: 'products',
                path: 'account:products',
                icon: 'box-alt',
                children: ['account:products:detail']
            },
            {
                name: 'documents',
                path: 'account:documents',
                icon: 'file-alt'
            }
        ]
    },
    commissionTabs: {
        type: 'selection',
        i18nPrefix: 'bo.commission.menu',
        data: [
            {
                name: 'set',
                path: 'account:commission:set',
                icon: 'cogs'
            },
            {
                name: 'transactions',
                path: 'account:commission:transactions',
                icon: 'align-justify'
            }
        ]
    }
};

const getters = {
    account: state => state.accountTabs,

    'account:commission': state => state.commissionTabs
};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
