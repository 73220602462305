import Vue from 'vue';

import { parseErrorMessageFromAPI } from '@/utils';

import UserDataset from '@lb-world/core/public/models/store/UserDataset';
import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

const state = {
    user: new UserDataset()
};

const getters = {
    user: state => state.user,
    'user:fetched': state => state.user.fetched,
    'user:id': state => state.user.data?.id,
    'user:email': state => state.user.data?.email,
    'user:fullName': state => state.user.getFullName()
};

const actions = {
    update: ({ commit, getters }, { user }) => {
        Vue.$log.debug('[ACTION] Running action with API call', user);

        return new Promise((resolve, reject) => {
            UserRepository.update(getters['user:id'], user)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    commit('update', user);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, user) => {
        state.user.storeData(user);
    },
    update: (state, data) => {
        state.user.updateData(data);
    },
    clear: state => {
        state.user.clearData();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
