var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"headers":_vm.headers,"page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"item.productType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('productTypes.' + item.productId))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:contracts:detail',
                    params: { id: item.id, return: _vm.returnRoute }
                }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.purchaseAmount, item.currency))+" "+_vm._s(item.currency)+" ")]}},{key:"item.openedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.openedAt))+" ")]}},{key:"item.activationDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(_vm.getActivationDate(item)))+" ")]}},{key:"item.commissionTotal",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCommissionTotal(item))+" ")]}},{key:"item.commissionPaidOut",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:contracts:detail',
                    params: { id: item.id, filters: { limitTo: 'own' }, return: _vm.returnRoute }
                }}},[_vm._v(" "+_vm._s(_vm.getCommissionPaidOut(item))+" ")])]}},{key:"item.commissionToBePaidOut",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:contracts:detail',
                    params: {
                        id: item.id,
                        filters: { monthFrom: _vm.currentMonth, monthTo: _vm.currentMonth },
                        return: _vm.returnRoute
                    }
                }}},[_vm._v(" "+_vm._s(_vm.getCommissionToBePaid(item))+" ")])]}},{key:"no-data",fn:function(){return [(_vm.fetchError)?_c('app-error',{attrs:{"horizontal":"","fetching":"","no-padding":""}}):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }