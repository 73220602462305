<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                EFK tokeny na platformě LBbroker jsou úročeny pevnou úrokovou sazbou. Výše úrokové sazby je závislá na
                výši vkladu a délce vkladu. Při automatické obnově vkladu je vklad pro další období úročen úrokovou
                sazbou platnou v den obnovy vkladu. Aktuální úrokové sazby jsou uvedeny v Oznámení LBworld o stanovených
                úrokových podmínkách a klientovi jsou k dispozici po přihlášení do svého účtu.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'CommissionEfkStake'
    };
</script>

<style scoped></style>
