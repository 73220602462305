<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                Výnos Klienta je determinován hodnotou EFK tokenu, za kterou je kupoval. Výše výnosu je pak určena
                rozdílem mezi realizovanou hodnotou směny a hodnotou EFK, za kterou Klient EFK tokeny pořizoval. Klient
                musí uvažovat poplatky, které jsou spojeny s tímto produktem.
            </p>

            <p class="mt-6">
                Aktuální poplatky jsou uvedeny v Oznámení LBworld o stanovených Poplatcích a klientovi jsou k dispozici
                po přihlášení do svého účtu.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'CommissionEfkLiquidity'
    };
</script>

<style scoped></style>
