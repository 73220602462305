import { render, staticRenderFns } from "./CommissionSet.vue?vue&type=template&id=3e88c741&scoped=true&"
import script from "./CommissionSet.vue?vue&type=script&lang=js&"
export * from "./CommissionSet.vue?vue&type=script&lang=js&"
import style0 from "./CommissionSet.vue?vue&type=style&index=0&id=3e88c741&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e88c741",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanels})
