import Vue from 'vue';
import VueRouter from 'vue-router';

import Public from '../views/Public';

import AccountLayout from '../views/account/AccountLayout';

import Dashboard from '../views/account/sections/Dashboard';
import Documents from '@/views/account/sections/Documents';
import Coworkers from '@/views/account/sections/Coworkers';

import Products from '@/views/account/sections/Products';
import ProductDetail from '@/views/account/sections/ProductDetail';

import Clients from '@/views/account/sections/Clients';
import ClientDetail from '@/views/account/sections/ClientDetail';

import Contracts from '@/views/account/sections/Contracts';
import ContractDetail from '@/views/account/sections/ContractDetail';

import Commission from '../views/account/sections/Commission';
import CommissionSet from '@/views/account/sections/commission/CommissionSet';
import CommissionTransactions from '@/views/account/sections/commission/CommissionTransactions';

import NotFound from '../views/error/NotFound';
import Maintenance from '@/views/error/Maintenance';
import ServerError from '@/views/error/ServerError';

import { userRouting } from '@/middleware/auth';
import { getUserDefaultLanguage, setLanguage } from '@lb-world/core/public/locales/engine';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'public',
        component: Public,
        children: [
            {
                path: 'account',
                beforeEnter: userRouting,
                component: AccountLayout,
                children: [
                    {
                        path: 'dashboard',
                        name: 'account:dashboard',
                        component: Dashboard
                    },
                    {
                        path: 'clients',
                        name: 'account:clients',
                        component: Clients
                    },
                    {
                        path: 'clients/:id',
                        name: 'account:clients:detail',
                        component: ClientDetail
                    },
                    {
                        path: 'coworkers',
                        name: 'account:coworkers',
                        component: Coworkers
                    },
                    {
                        path: 'commission',
                        name: 'account:commission',
                        component: Commission,
                        redirect: 'commission/set',
                        children: [
                            {
                                path: 'set',
                                name: 'account:commission:set',
                                component: CommissionSet
                            },
                            {
                                path: 'transactions',
                                name: 'account:commission:transactions',
                                component: CommissionTransactions
                            }
                        ]
                    },
                    {
                        path: 'contracts',
                        name: 'account:contracts',
                        component: Contracts
                    },
                    {
                        path: 'contracts/:id',
                        name: 'account:contracts:detail',
                        component: ContractDetail
                    },
                    {
                        path: 'products',
                        name: 'account:products',
                        component: Products
                    },
                    {
                        path: 'products/:id',
                        name: 'account:products:detail',
                        component: ProductDetail
                    },
                    {
                        path: 'documents',
                        name: 'account:documents',
                        component: Documents
                    }
                ]
            }
        ]
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/server-error',
        name: 'serverError',
        component: ServerError
    },
    {
        path: '*',
        name: 'notFound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    Vue.$log.debug('[LANG] Router: Checking for language present');

    const lang = getUserDefaultLanguage();

    if (lang) {
        Vue.$log.debug('[LANG] Router: Language to be present', lang);

        setLanguage(lang).then(() => next());
    } else {
        Vue.$log.debug('[LANG] Router: No language preference found');

        next();
    }
});

export default router;
