<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                DiGi Renta na platformě LBbroker je úročena pevnou úrokovou sazbou. Výše úrokové sazby je stanovena na
                7,1 % p.a. Nákup Digi Renty je umožněn pouze za EFK tokeny z privátního předprodeje na území České
                republiky. Ihned při nákupu Digi Renty je realizováno zvýšení hodnoty držených aktiv vzhledem ke
                směnnému kurzu EFK token/Digi Renta oproti ceně EFK tokenu v privátním předprodeji.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'CommissionDigiRent'
    };
</script>

<style scoped></style>
