<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                Na portálu LBworld v sekci LBbroker si v podsekci Produkty klient vybere EFK Nákup – Hodnota, zadá objem
                prostředků v CZK, za který bude chtít EFK tokeny nakupovat, a maximální výši kurzu EFK/USD, za který je
                ještě ochotný jeden dodatečný EFK token nakoupit. Minimální výše nákupu je 50.000, - CZK.
            </p>

            <p class="mt-6">
                Po dokončení pořízení produktu na portálu LBworld klient obdrží platební instrukce, smluvní podmínky
                produktu a Všeobecné obchodní podmínky LBworld.
            </p>

            <p class="mt-6">
                Po převedení prostředků se produkt aktivuje a začíná realizace. Částka se rozdělí na tzv. „loty“ po
                25.000, - CZK a první dva loty jdou do směny za USDt a k obchodování. Zbytek prostředků je k dispozici v
                CZK a do doby směny je možné tyto prostředky získat zpět (stornovat). Po směnění 1.lotu z USDt do EFK se
                3.lot mění z CZK do USDt a tak dál až do úplného směnění všech lotů v CZK a jejich kompletního vyčerpání
                z USDt nakoupením EFK. Do okamžiku směny z CZK do USDt loty nejsou zatíženy vstupním poplatkem, který
                činí 6 %.
            </p>

            <p class="mt-6">
                EFK tokeny se nakupují systematicky pomocí robotických algoritmů tak, aby nedocházelo na trhu k výrazným
                výkyvům ceny a klient za své prostředky obdržel co největší množství EFK.
            </p>

            <p class="mt-6">
                Po dokončení nákupu jsou EFK připsány na klientův účet u LBworld.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'IntroductionEfkPurchase'
    };
</script>

<style scoped></style>
