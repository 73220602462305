import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import TableDataset from '@lb-world/core/public/models/store/TableDataset';

import ProductsRepository from '@lb-world/core/public/api/repositories/ProductsRepository';
import CommissionReportsRepository from '@lb-world/core/public/api/repositories/CommissionReportsRepository';
import { deepCopy } from '@lb-world/core/public/utils/general';

const state = {
    expiringContracts: new TableDataset(),
    contracts: new TableDataset(),
    cdTransactions: new TableDataset()
};

const getters = {
    expiringContracts: state => state.expiringContracts.getItems(),
    expiringContractsFetched: state => state.expiringContracts.fetched,
    expiringContractsError: state => state.expiringContracts.fetchError,
    expiringContractsPages: state => state.expiringContracts.getPages(),
    expiringContractsFilter: state => state.expiringContracts.filter,

    contracts: state => state.contracts.getItems(),
    contractsFetched: state => state.contracts.fetched,
    contractsError: state => state.contracts.fetchError,
    contractsPages: state => state.contracts.getPages(),
    contractsFilter: state => state.contracts.filter,

    cdTransactions: state => state.cdTransactions.getItems(),
    cdTransactionsFetched: state => state.cdTransactions.fetched,
    cdTransactionsError: state => state.cdTransactions.fetchError,
    cdTransactionsPages: state => state.cdTransactions.getPages()
};

const actions = {
    fetch: ({ rootGetters, commit }, { page, filters, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const f = deepCopy(filters);

        f[f.commissionOwn ? 'ofImmediateParent' : 'ofParent'] = f.referral ? f.referral : rootGetters['user/user:id'];

        delete f['commissionOwn'];
        delete f['referral'];

        return new Promise((resolve, reject) => {
            ProductsRepository.getPA({
                columnOptions: { filters: f, sorts },
                apiOptions: { expand: 'user', page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'contracts', data: response.data, filter: filters });

                        resolve();
                    } else {
                        commit('error', 'contracts');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'contracts');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'contracts');

            resolve();
        });
    },

    'detail:contract:fetch': (_, productAccountId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ProductsRepository.getPADetail(productAccountId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:transactions:fetch': (_, { page, productAccountId, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ProductsRepository.getPAJointTransactions({
                data: { productAccountId },
                columnOptions: { sorts },
                apiOptions: { page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:commission:fetch': ({ rootGetters }, { page, productAccountId, filters, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        let userId;

        if (filters.referralId) {
            userId = filters.referralId;

            delete filters.referralId;
        } else {
            userId = rootGetters['user/user:id'];
        }

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getCommissionTransactions({
                data: {
                    userId,
                    productAccountId
                },
                columnOptions: {
                    filters,
                    sorts
                },
                apiOptions: { page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:transactions:export': (_, { productAccountId, exportTo, binary, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        console.log('FFFDFGSDS', productAccountId);

        const binaryOptions = binary ? { responseType: 'blob' } : null;

        return new Promise((resolve, reject) => {
            ProductsRepository.getPAJointTransactions({
                data: { productAccountId },
                columnOptions: { sorts },
                apiOptions: { exportTo, binaryOptions }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.status === 204) {
                        reject('export_no_data');
                    } else if (response.data) {
                        const searchKeyword = 'filename="';

                        const filenameHeader = response.headers['content-disposition'];
                        const filenameIndex = filenameHeader?.indexOf(searchKeyword);

                        let filename = 'lb-world_export.csv';

                        if (filenameIndex && filenameIndex !== -1) {
                            filename = filenameHeader.slice(filenameIndex + searchKeyword.length, -1);
                        }

                        resolve({ data: response.data, filename });
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:commission:export': ({ rootGetters }, { productAccountId, exportTo, binary, filters, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const binaryOptions = binary ? { responseType: 'blob' } : null;

        let userId;

        if (filters?.referralId) {
            userId = filters.referralId;

            delete filters.referralId;
        } else {
            userId = rootGetters['user/user:id'];
        }

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getCommissionTransactions({
                data: { userId, productAccountId },
                columnOptions: { filters, sorts },
                apiOptions: {
                    exportTo,
                    binaryOptions
                }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.status === 204) {
                        reject('export_no_data');
                    } else if (response.data) {
                        const searchKeyword = 'filename="';

                        const filenameHeader = response.headers['content-disposition'];
                        const filenameIndex = filenameHeader?.indexOf(searchKeyword);

                        let filename = 'lb-world_export.csv';

                        if (filenameIndex && filenameIndex !== -1) {
                            filename = filenameHeader.slice(filenameIndex + searchKeyword.length, -1);
                        }

                        resolve({ data: response.data, filename });
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'expiringContracts:fetch': ({ rootGetters, commit }, { page, filters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const f = deepCopy(filters);

        f[f.commissionOwn ? 'ofImmediateParent' : 'ofParent'] = f.referral ? f.referral : rootGetters['user/user:id'];
        f['toBeClosedSoon'] = true;

        delete f['commissionOwn'];
        delete f['referral'];

        return new Promise((resolve, reject) => {
            ProductsRepository.getPA({
                columnOptions: { filters: f },
                apiOptions: { expand: 'user', page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'expiringContracts', data: response.data, filter: filters });

                        resolve();
                    } else {
                        commit('error', 'expiringContracts');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'expiringContracts');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'expiringContracts:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'expiringContracts');

            resolve();
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data, filter }) => {
        state[key].storeData(data, filter);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
