<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                Produkt 4T na platformě LBbroker je úročený pevnou úrokovou sazbou. Výše úrokové sazby je stanovena na
                5,1 % p.a. Úroky se vyplácí kvartálně
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'Commission4Trans'
    };
</script>

<style scoped></style>
