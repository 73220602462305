<template>
    <app-row class="mt-3">
        <app-registration-col field>
            <app-text-stack>
                <template #key>
                    {{ $t('bo.contracts.detail.duration') }}
                </template>

                {{ contract.getDuration() }}
                {{ $tc('common.durations.' + contract.getDurationUnit(), contract.getDuration()) }}
            </app-text-stack>
        </app-registration-col>

        <app-registration-col field>
            <app-text-stack>
                <template #key>
                    {{ $t('bo.contracts.detail.interestRate') }}
                </template>

                {{ contract.getInterestRate() }}
            </app-text-stack>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'CDetailEFKStake',

        props: ['contract']
    };
</script>

<style scoped></style>
