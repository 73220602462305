<template>
    <app-container unlimited-width decreased-page-padding>
        <app-detail-header :loading="!client.fetched" icon="users" :on-back-pressed="onBackPressed">
            {{ client.getFullName() }}
        </app-detail-header>

        <app-loader v-if="!client.fetched" wrapper class="mb-3" />

        <app-row v-else-if="$vuetify.breakpoint.smAndUp">
            <app-registration-col whole-screen>
                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.type') }}</p>
                        <p class="data-container__item data-container__item--highlight">Fyzická osoba</p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('bo.contracts.detail.client.phone') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            <app-loader v-if="!client.phone" />
                            <span v-else>{{ client.phone.getPhone() }}</span>
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.personalNumber') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.personalInfo.getPersonalNumber() }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.email') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.getEmail() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.document.type') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ $t(client.identityDocument.getDocumentType()) }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('address.title') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.address.getLine1() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.document.number') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.identityDocument.getDocumentNumber() }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('address.city') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.address.getCity() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.document.dateOfExpiry') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.identityDocument.getDocumentValidity().expiry }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('address.country') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.address.getCountry() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.document.authority') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.identityDocument.getDocumentAuthority().entity }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">{{ $t('address.postalCode') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.address.getPostalCode() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.placeOfBirth') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.personalInfo.getBirthPlace() }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">UID</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.getId() }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.dateOfBirth') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.personalInfo.getBirthDate() }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right">
                        <p class="data-container__item">
                            {{ $t('bo.clients.detail.basicInfo.approvedLimit') }}
                        </p>

                        <app-loader
                            class="data-container__item data-container__item--highlight"
                            v-if="!clientAML"
                            justify="end"
                        />
                        <p v-else class="data-container__item data-container__item--highlight">
                            {{ clientLimit }}
                        </p>
                    </div>
                </div>

                <div class="data-container">
                    <div class="data-container__layout data-container__layout--left">
                        <p class="data-container__item">{{ $t('bo.clients.detail.basicInfo.referral') }}</p>
                        <p class="data-container__item data-container__item--highlight">
                            {{ client.getReferralFullName() }}
                        </p>
                    </div>

                    <div class="data-container__layout data-container__layout--right" />
                </div>
            </app-registration-col>
        </app-row>

        <app-row v-else>
            <app-registration-col whole-screen>
                <app-text-stack>
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.type') }}
                    </template>

                    Fyzická osoba
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.personalNumber') }}
                    </template>

                    {{ client.personalInfo.getPersonalNumber() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.document.type') }}
                    </template>

                    {{ $t(client.identityDocument.getDocumentType()) }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.document.number') }}
                    </template>

                    {{ client.identityDocument.getDocumentNumber() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.document.dateOfExpiry') }}
                    </template>

                    {{ client.identityDocument.getDocumentValidity().expiry }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.document.authority') }}
                    </template>

                    {{ client.identityDocument.getDocumentAuthority().entity }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.placeOfBirth') }}
                    </template>

                    {{ client.personalInfo.getBirthPlace() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.dateOfBirth') }}
                    </template>

                    {{ client.personalInfo.getBirthDate() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.contracts.detail.client.phone') }}
                    </template>

                    {{ client.phone.getPhone() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.email') }}
                    </template>

                    {{ client.getEmail() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('address.streetLine', { number: 1 }) }}
                    </template>

                    {{ client.address.getLine1() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('address.city') }}
                    </template>

                    {{ client.address.getCity() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('address.country') }}
                    </template>

                    {{ client.address.getCountry() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('address.postalCode') }}
                    </template>

                    {{ client.address.getPostalCode() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.referral') }}
                    </template>

                    {{ client.getReferralFullName() }}
                </app-text-stack>

                <app-text-stack class="mt-6">
                    <template #key>
                        {{ $t('bo.clients.detail.basicInfo.approvedLimit') }}
                    </template>

                    <app-loader v-if="!clientAML" />
                    <span v-else>{{ clientLimit }}</span>
                </app-text-stack>
            </app-registration-col>
        </app-row>

        <app-divider :bottom-margin="0" />

        <app-title>{{ $t('bo.clients.detail.clientPortfolio') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <app-card>
                    <client-detail-portfolio-table
                        :client-id="clientId"
                        :items="portfolio.getItems()"
                        :loading="!portfolio.fetched"
                        :fetch-error="portfolio.fetchError"
                        :pages="portfolio.getPages()"
                        @pagination:change="fetchPortfolio"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import TableDataset from '@lb-world/core/public/models/store/TableDataset';
    import UserDataset from '@lb-world/core/public/models/store/UserDataset';
    import ClientDetailPortfolioTable from '@/components/module-user/clients/ClientDetailPortfolioTable';
    import AppDetailHeader from '@lb-world/core/public/components/utils/AppDetailHeader';

    export default {
        name: 'ClientDetail',

        components: {
            AppDetailHeader,
            ClientDetailPortfolioTable
        },

        data: () => ({
            openedPanels: 0,

            clientId: null,

            clientAML: null,
            client: new UserDataset(),
            portfolio: new TableDataset()
        }),

        watch: {
            $route() {
                this.clientId = this.$route.params?.id;

                this.fetchClient();
            }
        },

        computed: {
            clientLimit() {
                const limits = [3, 2, 1];

                for (const limit in limits) {
                    if (this.clientAML?.['limit' + limit]?.approved) {
                        return 'Limit ' + limit;
                    }
                }

                return this.$t('common.none');
            }
        },

        methods: {
            onBackPressed() {
                const returnRoute = this.$route.params?.return;

                this.$router.push(returnRoute ? returnRoute : { name: 'account:clients' });
            },

            fetchClient() {
                this.$store.dispatch('clients/detail:fetch', this.clientId).then(client => {
                    this.client.storeData(client);

                    this.fetchContractAML();
                });
            },

            fetchContractAML() {
                this.$store.dispatch('clients/detail:aml:fetch', this.clientId).then(aml => {
                    this.clientAML = aml;
                });
            },

            fetchPortfolio(page) {
                this.portfolio.clearData();

                this.$store
                    .dispatch('clients/detail:portfolio:fetch', { page, user: this.clientId })
                    .then(portfolio => {
                        this.portfolio.storeData(portfolio);
                    });
            }
        },

        mounted() {
            this.clientId = this.$route.params?.id;

            this.fetchClient();
            this.fetchPortfolio();
        }
    };
</script>

<style scoped lang="scss">
    .data-container {
        display: flex;

        @include media.resolve('xs-only') {
            flex-direction: column;
        }

        .data-container__layout {
            display: flex;
            flex-grow: 1;
            max-width: 50%;

            margin-bottom: 12px;

            @include media.resolve('md-and-down') {
                flex-direction: column;
                margin-bottom: 24px;
            }

            &.data-container__layout--left {
                padding-right: 24px;
            }

            &.data-container__layout--right {
                padding-left: 36px;
            }

            .data-container__item {
                flex-grow: 1;
                max-width: 50%;

                @include media.resolve('md-and-down') {
                    max-width: 100%;
                }

                &.data-container__item--highlight {
                    @include textUtils.popover-category;

                    @include media.resolve('lg-and-up') {
                        text-align: end;
                    }
                }
            }
        }
    }
</style>
