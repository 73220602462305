<template>
    <div :class="['d-flex', 'justify-' + justify]">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ButtonWrapper',

        props: {
            justify: {
                type: String,
                default: 'center'
            }
        }
    };
</script>

<style scoped></style>
