<template>
    <app-card>
        <table-filter-bar
            :pre-applied="commissionTable.preAppliedFilters"
            :filters.sync="commissionTable.filters"
            :custom-default="commissionTable.customDefault"
            custom-default-visible
            @pagination:change="fetchCommissionTransactions"
            @filter:update="filterCommissionTransactions"
            @filter:reset="filterCommissionTransactions"
        >
            <app-row justify="center">
                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.viewAsClient')"
                        v-model="commissionTable.filters.viewAsClient"
                        :loading="!treeFetched"
                        :items="treeReferrals"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.currency')"
                        :items="currencies"
                        v-model="commissionTable.filters.currency"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.limitTo')"
                        :items="commissionTypes"
                        v-model="commissionTable.filters.limitTo"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.product')"
                        :items="productTypes"
                        v-model="commissionTable.filters.productId"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthFrom')"
                        :menu.sync="calendar.monthFrom"
                        v-model="commissionTable.filters.monthFrom"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthTo')"
                        :menu.sync="calendar.monthTo"
                        v-model="commissionTable.filters.monthTo"
                    />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <app-divider />

        <app-row v-if="!salesmanReportDynamicCommissionsFetched">
            <app-registration-col whole-screen>
                <skeleton-commission-set />
                <skeleton-commission-set class="mt-6" />
            </app-registration-col>
        </app-row>

        <app-error v-else-if="salesmanReportDynamicCommissionsError" horizontal fetching />

        <div
            v-else
            v-for="(balance, currency, index) in salesmanReportDynamicCommissions"
            :key="currency"
            :class="{ 'mb-3': !isLastItem(index, salesmanReportDynamicCommissions) }"
        >
            <app-row>
                <app-registration-col whole-screen>
                    <div class="loaded-commission">
                        <app-text-stack
                            v-color:[currency].translucent
                            class="loaded-commission__stack"
                            :key="currency + '_' + type.value"
                            v-for="type in commissionTypes"
                        >
                            <template #key>
                                {{ $t(type.name) }}
                            </template>

                            {{ formatAmount(balance[type.value], currency) }}

                            <img
                                :alt="currency"
                                class="loaded-commission__currency-icon"
                                :src="require('@lb-world/core/public/assets/crypto/' + currency + '.svg')"
                            />

                            <span :style="{ color: getCurrencyColor(currency) }">{{ currency }}</span>
                        </app-text-stack>
                    </div>
                </app-registration-col>
            </app-row>
        </div>

        <app-divider :bottom-margin="0" />

        <app-row>
            <app-registration-col whole-screen>
                <v-expansion-panels>
                    <app-expansion-panel :title="$t('bo.commission.set.export.title')" additional-data="CSV, PDF">
                        <app-text>
                            <span v-html="$t('bo.commission.set.export.description')" />
                        </app-text>

                        <app-row>
                            <app-registration-col whole-screen class="pa-0">
                                <app-flex>
                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="exportTo('csv')"
                                            :loading="exportButtonLoading['csv']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'CSV' }) }}
                                        </button-secondary>
                                    </app-flex-item>

                                    <app-flex-item class="four-items">
                                        <button-secondary
                                            inline
                                            full-width
                                            @click="exportTo('pdf')"
                                            :loading="exportButtonLoading['pdf']"
                                            icon="file-export"
                                        >
                                            {{ $t('bo.contracts.detail.export.button', { format: 'PDF' }) }}
                                        </button-secondary>
                                    </app-flex-item>
                                </app-flex>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>
            </app-registration-col>
        </app-row>

        <app-divider />

        <app-row>
            <app-registration-col whole-screen>
                <commission-set-table
                    :loading="!salesmanReportDynamicTransactionsFetched"
                    :items="salesmanReportDynamicTransactions"
                    :filters.sync="commissionTable.filters"
                    :fetch-error="salesmanReportDynamicTransactionsError"
                    :pages="salesmanReportDynamicTransactionsPages"
                    :pre-applied="commissionTable.preAppliedFilters"
                    :custom-default="commissionTable.customDefault"
                    @pagination:change="fetchCommissionTransactions"
                    @filter:update="filterCommissionTransactions"
                    @filter:reset="filterCommissionTransactions"
                    @sort:update="sortCommissionTransactions"
                />
            </app-registration-col>
        </app-row>
    </app-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { PTFactory } from '@lb-world/core/public/static/productTypes';

    import moment from 'moment';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppSelectField from '@/components/ui/form/AppSelectField';
    import AppDatePicker from '@/components/ui/form/AppDatePicker';
    import TableFilterBar from '@lb-world/core/public/components/tables/TableFilterBar';
    import Currencies from '@lb-world/core/public/utils/currencies';
    import CommissionSetTable from '@/components/ui/tables/CommissionSetTable';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import SkeletonCommissionSet from '@/components/ui/skeletons/SkeletonCommissionSet';

    export default {
        name: 'CommissionSet',

        components: {
            SkeletonCommissionSet,
            AppFlex,
            AppFlexItem,
            AppExpansionPanel,
            CommissionSetTable,
            TableFilterBar,
            AppDatePicker,
            AppSelectField
        },

        data: () => ({
            productTypes: PTFactory.getSalesmanCommission(),

            commissionTable: {
                page: 1,
                sorts: null,
                customDefault: {
                    monthFrom: '',
                    monthTo: '',
                    limitTo: 'total'
                },
                preAppliedFilters: {
                    monthFrom: '',
                    monthTo: '',
                    limitTo: ''
                },
                filters: {
                    viewAsClient: '',
                    productId: '',
                    limitTo: '',
                    currency: '',
                    monthFrom: '',
                    monthTo: ''
                }
            },

            calendar: {
                monthFrom: false,
                monthTo: false
            },

            exportButtonLoading: {
                csv: false,
                pdf: false
            },

            commissionTypes: [
                { name: 'bo.commissionTypes.own', value: 'own' },
                { name: 'bo.commissionTypes.team', value: 'team' },
                { name: 'bo.commissionTypes.total', value: 'total' }
            ]
        }),

        computed: {
            ...mapGetters('clients', {
                treeFetched: 'tree:fetched',
                treeData: 'tree:children',
                treeError: 'tree:error',
                treeReferrals: 'tree:selectBox'
            }),

            ...mapGetters('reports', [
                'salesmanReportDynamicCommissions',
                'salesmanReportDynamicCommissionsFetched',
                'salesmanReportDynamicCommissionsError',
                'salesmanReportDynamicTransactions',
                'salesmanReportDynamicTransactionsFetched',
                'salesmanReportDynamicTransactionsError',
                'salesmanReportDynamicTransactionsFilter',
                'salesmanReportDynamicTransactionsPages'
            ]),

            currentMonth() {
                return moment().format('YYYY-MM');
            },

            currencies() {
                return Currencies.getCurrencies('commissionPayout');
            },

            filterParams() {
                return this.$route.params?.filterParams;
            }
        },

        methods: {
            getCurrencyColor(currency) {
                return Currencies.getColor(currency);
            },

            isLastItem(index, object) {
                const objectLength = Object.keys(object)?.length;

                return objectLength > 0 ? index <= objectLength - 1 : true;
            },

            downloadCSVData(data, exportFilename) {
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + escape(data);
                anchor.target = '_blank';
                anchor.download = exportFilename;
                anchor.click();
            },

            downloadBinaryData(data, filename) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
            },

            exportTo(format) {
                this.exportButtonLoading[format] = true;

                this.$store
                    .dispatch('reports/salesmanReport:dynamic:export', {
                        format,
                        sorts: this.commissionTable.sorts,
                        filters: this.commissionTable.filters,
                        binary: format === 'pdf'
                    })
                    .then(({ data, filename }) => {
                        if (format === 'pdf') {
                            this.downloadBinaryData(data, filename);
                        } else {
                            this.downloadCSVData(data, filename);
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.exportButtonLoading[format] = false;
                    });
            },

            filterCommissionTransactions(filters) {
                this.commissionTable.page = 1;
                this.commissionTable.filters = this.deepCopy(filters);

                this.$store.dispatch('reports/salesmanReport:dynamic:clear').then(() => {
                    this.$store.dispatch('reports/salesmanReport:dynamic:fetch', {
                        page: this.commissionTable.page,
                        sorts: this.commissionTable.sorts,
                        filters
                    });
                });
            },

            sortCommissionTransactions(sorts) {
                this.commissionTable.sorts = sorts;

                this.fetchCommissionTransactions(1);
            },

            fetchCommissionTransactions(page) {
                if (this.filterParams) {
                    for (const index in this.filterParams) {
                        const filterParam = this.filterParams[index];

                        this.$set(this.commissionTable.filters, index, filterParam);
                        this.$set(this.commissionTable.preAppliedFilters, index, filterParam);
                    }

                    this.$set(this.commissionTable.filters, 'monthFrom', this.currentMonth);
                    this.$set(this.commissionTable.preAppliedFilters, 'monthFrom', this.currentMonth);

                    this.$set(this.commissionTable.filters, 'monthTo', this.currentMonth);
                    this.$set(this.commissionTable.preAppliedFilters, 'monthTo', this.currentMonth);

                    this.$set(this.commissionTable.filters, 'product', '');
                    this.$set(this.commissionTable.preAppliedFilters, 'product', '');
                }

                this.commissionTable.page = page;

                this.$store.dispatch('reports/salesmanReport:dynamic:clear').then(() => {
                    this.$store.dispatch('reports/salesmanReport:dynamic:fetch', {
                        page,
                        sorts: this.commissionTable.sorts,
                        filters: this.commissionTable.filters
                    });
                });
            }
        },

        mounted() {
            if (!this.treeFetched) {
                this.$store.dispatch('clients/tree:fetch');
            }

            this.commissionTable.customDefault.monthFrom = this.currentMonth;
            this.commissionTable.customDefault.monthTo = this.currentMonth;

            if (this.salesmanReportDynamicTransactionsFilter) {
                this.$set(this.commissionTable, 'preAppliedFilters', this.salesmanReportDynamicTransactionsFilter);
            } else {
                this.$set(this.commissionTable.filters, 'monthFrom', this.currentMonth);
                this.$set(this.commissionTable.preAppliedFilters, 'monthFrom', this.currentMonth);

                this.$set(this.commissionTable.filters, 'monthTo', this.currentMonth);
                this.$set(this.commissionTable.preAppliedFilters, 'monthTo', this.currentMonth);
            }

            if (!this.commissionTable.filters.limitTo) {
                this.$set(this.commissionTable.preAppliedFilters, 'limitTo', 'total');
                this.$set(this.commissionTable.filters, 'limitTo', 'total');
            }

            if (
                !this.salesmanReportDynamicCommissionsFetched ||
                !this.salesmanReportDynamicTransactionsFetched ||
                this.filterParams
            ) {
                this.fetchCommissionTransactions();
            }
        }
    };
</script>

<style scoped lang="scss">
    .payment-date {
        display: flex;
        align-items: center;

        .payment-date__text {
            margin-right: spacing.$spacing-1;
            margin-bottom: 0 !important;
        }
    }

    .loaded-commission {
        display: flex;

        &::v-deep .loaded-commission__stack {
            margin-right: spacing.$spacing-2;
            padding: spacing.$spacing-3;
            border-radius: spacing.$spacing-2;
            width: 20%;
        }

        &::v-deep .loaded-commission__currency-icon {
            height: 14px;

            margin-left: spacing.$spacing-2;
            margin-right: spacing.$spacing-1;
        }

        @include media.resolve('md-only') {
            &::v-deep .loaded-commission__stack {
                width: 25%;
            }
        }

        @include media.resolve('sm-only') {
            &::v-deep .loaded-commission__stack {
                width: 33%;
            }
        }

        @include media.resolve('xs-only') {
            flex-direction: column;

            &::v-deep .loaded-commission__stack {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: spacing.$spacing-3;
                }
            }
        }
    }
</style>
