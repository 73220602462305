<template>
    <router-link
        :to="{
            name: 'account:products:detail',
            params: { id: product.id, return: { name: 'account:products' } }
        }"
    >
        <app-card>
            <app-title>
                <div class="product-card">
                    <div>
                        <font-awesome-icon size="sm" class="mr-2" :icon="['fad', 'box-alt']" />
                        {{ $t('productTypes.' + product.id) }}
                    </div>

                    <font-awesome-icon size="sm" :icon="['fas', 'chevron-right']" />
                </div>
            </app-title>

            <app-row>
                <app-registration-col class="pt-0 text-start">
                    <span v-color:finance>{{ $t('bo.products.categories.' + product.category) }}</span>
                </app-registration-col>
            </app-row>
        </app-card>
    </router-link>
</template>

<script>
    export default {
        name: 'ProductCard',

        props: ['product']
    };
</script>

<style scoped lang="scss">
    .product-card {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include textUtils.popover-category;
    }
</style>
