<template>
    <app-container unlimited-width decreased-page-padding>
        <loaded-commission-overview @transactions:fetch="fetchCommissionTransactions" />

        <app-divider :bottom-margin="0" />

        <app-row>
            <app-registration-col whole-screen>
                <app-tabs :tabs="commissionTabs" :i18n-prefix="commissionTabs.i18nPrefix" />

                <router-view />
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import LoadedCommissionOverview from '@/components/module-user/commissions/LoadedCommissionOverview';
    import AppTabs from '@/components/ui/tabs/AppTabs';

    export default {
        name: 'Commission',

        components: {
            AppTabs,
            LoadedCommissionOverview
        },

        computed: {
            commissionTabs() {
                return this.$store.getters['tabs/account:commission'];
            }
        },

        methods: {
            fetchCommissionTransactions(page) {
                this.$store.dispatch('commission/transactions:clear').then(() => {
                    this.$store.dispatch('commission/transactions:fetch', {
                        page
                    });
                });
            }
        }
    };
</script>

<style scoped></style>
