<template>
    <div>
        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
            :options.sync="options"
            multi-sort
        >
            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template #item.createdAt="{ item }">
                {{ formatTimestamp(item.createdAt) }}
            </template>

            <template #item.amount="{ item }">
                {{ formatAmount(item.amount, item.currency) }}
            </template>

            <template #item.type="{ item }"> {{ $t('transactionTypes.' + item.type) }} </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, pagination, sorting } from '@lb-world/core/public/components/tables/mixins';

    import { createHeaderItem } from '@lb-world/core/public/components/tables/utils';

    export default {
        name: 'ContractDetailTransactionsTable',

        mixins: [core, pagination, sorting],

        computed: {
            headers() {
                return [
                    createHeaderItem(this.$t('table.columns.id'), 'id'),
                    createHeaderItem(this.$t('table.columns.type'), 'type', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.createdAt'), 'createdAt', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.amount'), 'amount', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.currency'), 'currency', { sortable: true })
                ];
            }
        }
    };
</script>

<style scoped></style>
