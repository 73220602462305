import Vue from 'vue';

import { API_SERVER_ERROR } from '@lb-world/core/public/api/utils';

import { parseErrorMessageFromAPI } from '@/utils';

import StorageRepository from '@lb-world/core/public/api/repositories/StorageRepository';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

const state = {
    files: new BaseDataset()
};

const getters = {
    files: state => state.files.data,
    filesFetched: state => state.files.fetched,
    filesError: state => state.files.fetchError,

    categories: state => state.files.data?.categories
};

const actions = {
    'files:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.get()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'files',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'files');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'files');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'file:download': (_, fileId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            Vue.$log.debug('[ACTION] UserID is defined', fileId);

            StorageRepository.downloadFile(fileId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
