<template>
    <app-container id="account" shadow fill-height auto-width>
        <app-container unlimited-width>
            <div class="account__section-container">
                <app-page-title v-if="sectionName" :title="$t('bo.menu.' + sectionName)" :icon="sectionIcon">
                    <template #float-right>
                        <user-info-panel :username="username" :user-id="userId" />
                    </template>
                </app-page-title>
            </div>

            <router-view />
        </app-container>
    </app-container>
</template>

<script>
    import UserInfoPanel from '@/components/ui/UserInfoPanel';

    export default {
        name: 'AccountLayout',

        components: { UserInfoPanel },

        data: () => ({
            drawer: null,

            sectionName: '',
            sectionIcon: ''
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/account'];
            },
            username() {
                return this.$store.getters['user/user:email'];
            },
            userId() {
                return this.$store.getters['user/user:id'];
            }
        },

        methods: {
            determineRoute() {
                const sectionObject = this.tabs.data.find(t => {
                    if (t.children?.includes(this.$route.name)) {
                        return true;
                    }

                    return t.path === this.$route.name;
                });

                if (sectionObject) {
                    this.sectionName = sectionObject.name;
                    this.sectionIcon = sectionObject.icon;
                }
            }
        },

        watch: {
            $route: {
                handler() {
                    this.determineRoute();
                }
            }
        },

        mounted() {
            this.determineRoute();
        }
    };
</script>

<style scoped lang="scss">
    .account__section-container {
        display: flex;
        align-items: center;

        padding-top: spacing.$spacing-6;
        padding-left: spacing.$spacing-3;
        padding-right: spacing.$spacing-3;

        @include media.resolve('md-and-up') {
            padding-left: spacing.$spacing-9;
            padding-right: spacing.$spacing-9;
        }
    }
</style>
