<template>
    <v-btn
        :small="small"
        text
        v-bind="$attrs"
        v-on="$listeners"
        class="v-btn--secondary"
        :class="[
            'justify-' + justify,
            {
                'full-width': alwaysFullWidth || (fullWidth && $vuetify.breakpoint.xs),
                inline,
                form,
                cardSelect,
                iconButton
            }
        ]"
    >
        <font-awesome-icon v-if="icon" :icon="[iconSet, icon]" :class="{ 'mr-2': !iconButton }" />

        <slot />
    </v-btn>
</template>

<script>
    export default {
        name: 'ButtonSecondary',

        props: {
            small: Boolean,
            icon: String,
            iconButton: Boolean,
            fullWidth: Boolean,
            alwaysFullWidth: Boolean,
            inline: Boolean,
            form: Boolean,
            cardSelect: Boolean,
            centered: {
                type: Boolean,
                default: false
            },
            iconSet: {
                type: String,
                default: 'fad'
            },
            justify: {
                type: String,
                default: 'center'
            }
        }
    };
</script>

<style scoped></style>
