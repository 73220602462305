<template>
    <sign-in v-if="!(tokenFetched && dismissLoadingScreen)" />

    <router-view v-else />
</template>

<script>
    import SignIn from '@/components/ui/SignIn';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getWorldSignInUrlFormat } from '@/utils';
    import { checkAccessToken, userFetch } from '@/middleware/auth';

    import { MAINTENANCE_ROUTE, SERVER_ERROR_ROUTE } from '@/overlays';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    export default {
        name: 'Public',

        components: { SignIn },

        computed: {
            token() {
                return this.$store.getters['auth/token'];
            },
            tokenFetched() {
                return this.$store.getters['auth/token:fetched'];
            },
            userRegistered() {
                return this.$store.getters['auth/token:role'](UserRoles.REGISTERED);
            },
            userSalesman() {
                return this.$store.getters['auth/token:role'](UserRoles.SALESMAN);
            },
            userFetched() {
                return this.$store.getters['user/user:fetched'];
            },
            dismissLoadingScreen() {
                return this.token && this.userFetched && this.userRegistered && this.userSalesman;
            }
        },

        methods: {
            checkAccessToken() {
                if (this.tokenFetched) {
                    return;
                }

                const route = this.$route.query.redirect ?? {
                    name: 'account:dashboard'
                };

                checkAccessToken()
                    .then(() => {
                        userFetch()
                            .then(() => {
                                this.pushRoute(route);
                            })
                            .catch(error => {
                                if (error) EventBus.$emit('snackbar:danger:show', error);
                            });
                    })
                    .catch(error => {
                        if (error === MAINTENANCE_ROUTE || error === SERVER_ERROR_ROUTE) {
                            this.$router.push(error);
                        } else {
                            window.location = getWorldSignInUrlFormat();
                        }
                    });
            },

            pushRoute(route) {
                this.$log.debug('[SYSTEM] Pushing route (from, to)', this.$route?.path, route);

                const routePath = typeof route === 'object' ? route.path : route;

                if (this.$route?.path !== routePath) {
                    this.$router.replace(route);
                }
            }
        },

        updated() {
            this.$log.debug('[SYSTEM] UserSignIn component updated');

            this.checkAccessToken();
        },

        mounted() {
            this.$log.debug('[SYSTEM] UserSignIn component mounted');

            this.checkAccessToken();
        }
    };
</script>
