<template>
    <v-app class="app" :class="{ account: tokenFetched && dismissLoadingScreen }">
        <app-snackbar />

        <app-sidebar
            v-if="tokenFetched && dismissLoadingScreen"
            :drawer="drawer"
            :sections="tabs"
            @drawer:input="drawer = $event"
        />

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppSnackbar from './components/ui/AppSnackbar';

    export default {
        name: 'App',

        components: { AppSnackbar },

        data: () => ({
            drawer: false
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/account'];
            },
            token() {
                return this.$store.getters['auth/token'];
            },
            tokenFetched() {
                return this.$store.getters['auth/token:fetched'];
            },
            userRegistered() {
                return this.$store.getters['auth/token:role'](UserRoles.REGISTERED);
            },
            userSalesman() {
                return this.$store.getters['auth/token:role'](UserRoles.SALESMAN);
            },
            userFetched() {
                return this.$store.getters['user/user:fetched'];
            },
            dismissLoadingScreen() {
                return this.token && this.userFetched && this.userRegistered && this.userSalesman;
            }
        },

        created() {
            this.$log.debug('[SYSTEM] Running in mode: ' + process.env.VUE_APP_MODE);

            EventBus.$on('drawer:input', () => {
                this.drawer = !this.drawer;
            });

            EventBus.$on('jwt:refresh', () => {
                this.$log.debug('[JWT] Signal to refresh token received, dispatching action');

                this.$store
                    .dispatch('auth/token:store')
                    .then(({ expiration }) => {
                        this.$store.dispatch('auth/token:refresh', expiration);
                    })
                    .catch(({ error }) => {
                        this.$log.debug('[JWT] Dispatch action to refresh token failed');

                        EventBus.$emit('snackbar:show:danger', error);

                        this.$router.push({ name: 'account:services' });
                    });
            });
        }
    };
</script>

<style lang="scss">
    @import 'scss/main';
</style>
