<template>
    <app-row>
        <app-registration-col whole-screen>
            TODO
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'ParametersEfkPurchase'
    };
</script>

<style scoped></style>
