<template>
    <app-card>
        <app-row>
            <app-registration-col whole-screen>
                <commission-transactions-table
                    :loading="!transactionsFetched"
                    :items="transactions"
                    :fetch-error="transactionsError"
                    :pages="transactionsPages"
                    @pagination:change="fetchCommissionTransactions"
                />
            </app-registration-col>
        </app-row>
    </app-card>
</template>

<script>
    import CommissionTransactionsTable from '@/components/ui/tables/CommissionTransactionsTable';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CommissionTransactions',

        components: { CommissionTransactionsTable },

        computed: {
            ...mapGetters('commission', [
                'transactions',
                'transactionsFetched',
                'transactionsError',
                'transactionsPages'
            ])
        },

        methods: {
            fetchCommissionTransactions(page) {
                this.$store.dispatch('commission/transactions:clear').then(() => {
                    this.$store.dispatch('commission/transactions:fetch', {
                        page
                    });
                });
            }
        },

        created() {
            if (!this.transactionsFetched) {
                this.fetchCommissionTransactions();
            }
        }
    };
</script>

<style scoped></style>
