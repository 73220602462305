var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-row',[_c('app-registration-col',[_c('app-switch-field',{attrs:{"centered":_vm.$vuetify.breakpoint.xsOnly,"hide-hint":"","label":_vm.$t('filters.commissionOwn')},on:{"change":function($event){return _vm.$emit('filter:update')}},model:{value:(_vm.filters.commissionOwn),callback:function ($$v) {_vm.$set(_vm.filters, "commissionOwn", $$v)},expression:"filters.commissionOwn"}})],1)],1),_c('hr',{staticClass:"mt-6"}),_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","disable-sort":"","loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:contracts:detail',
                    params: { id: item.id, return: { name: 'account:dashboard' } }
                }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.productId",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('productTypes.' + item.productId))+" ")]}},{key:"item.expiryDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.shouldBeClosedAt))+" ")]}},{key:"item.client",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:clients:detail',
                    params: { id: item.user.id, return: { name: 'account:dashboard' } }
                }}},[_vm._v(" "+_vm._s(_vm.getClient(item.user))+" ")])]}},{key:"item.referral",fn:function(ref){
                var item = ref.item;
return [(_vm.hasReferralAvailable(item))?_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:coworkers',
                    params: { id: _vm.getReferralId(item), return: { name: 'account:dashboard' } }
                }}},[_vm._v(" "+_vm._s(_vm.getReferralName(item))+" ")]):_c('span',[_vm._v(_vm._s(_vm.getReferralName(item)))])]}},{key:"no-data",fn:function(){return [(_vm.fetchError)?_c('app-error',{attrs:{"horizontal":"","fetching":"","no-padding":""}}):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }