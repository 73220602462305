<template>
    <app-row>
        <app-registration-col whole-screen>
            <v-simple-table>
                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">Délka prodloužení</span></td>
                        <td>30 dní s možným prodloužením</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Minimální vklad</span></td>
                        <td>10 000 EFK</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold"> Maximální vklad</span></td>
                        <td>neomezeno</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'ParametersEfkLiquidity'
    };
</script>

<style scoped></style>
