<template>
    <app-container unlimited-width decreased-page-padding>
        <app-row>
            <app-registration-col whole-screen>
                <app-card table left>
                    <contracts-table
                        :loading="!contractsFetched"
                        :items="contracts"
                        :pages="contractsPages"
                        :fetch-error="contractsError"
                        :filters.sync="contractsTable.filters"
                        :custom-default="contractsTable.customDefaultFilters"
                        :pre-applied="contractsTable.preAppliedFilters"
                        @pagination:change="fetchContracts"
                        @filter:update="filterContracts"
                        @filter:reset="filterContracts"
                        @sort:update="sortContracts"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import ContractsTable from '@/components/ui/tables/ContractsTable';

    export default {
        name: 'Contracts',

        components: { ContractsTable },

        computed: {
            ...mapGetters('contracts', [
                'contracts',
                'contractsFetched',
                'contractsError',
                'contractsPages',
                'contractsFilter'
            ])
        },

        data: () => ({
            contractsTable: {
                page: 1,
                sorts: null,
                preAppliedFilters: null,
                customDefaultFilters: {
                    commissionOwn: null
                },
                filters: {
                    id: '',
                    product: '',
                    monthFrom: '',
                    monthTo: '',
                    referral: '',
                    commissionOwn: null
                }
            }
        }),

        methods: {
            fetchContracts(page) {
                this.contractsTable.page = page;

                this.$store.dispatch('contracts/clear').then(() => {
                    this.$store
                        .dispatch('contracts/fetch', {
                            page,
                            filters: this.contractsTable.filters,
                            sorts: this.contractsTable.sorts
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            filterContracts(filters) {
                this.contractsTable.filters = filters;
                this.contractsTable.page = 1;

                this.$store.dispatch('contracts/clear').then(() => {
                    this.$store
                        .dispatch('contracts/fetch', {
                            page: this.contractsTable.page,
                            filters,
                            sorts: this.contractsTable.sorts
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            sortContracts(sorts) {
                this.contractsTable.sorts = sorts;

                this.fetchContracts(1);
            }
        },

        mounted() {
            if (this.contractsFilter) {
                this.$set(this.contractsTable, 'preAppliedFilters', this.contractsFilter);
            }

            if (!this.contractsFetched) {
                this.fetchContracts();
            }
        }
    };
</script>

<style scoped></style>
