<template>
    <app-row>
        <app-registration-col whole-screen>
            <v-simple-table>
                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">Účel úvěru</span></td>
                        <td>Zvýšení objemu aktivit úvěrovaného</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Délka trvání</span></td>
                        <td>3 roky</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Nominální hodnota participace</span></td>
                        <td>10.000 Kč</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Úroková sazba</span></td>
                        <td>5,1 % p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Upisovací období produktu</span></td>
                        <td>2 roky</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Maximální objem úvěru</span></td>
                        <td>200.000.000 Kč</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Maximální participace od klienta</span></td>
                        <td>Není stanovena</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Poplatek za ukončení</span></td>
                        <td>0% při dodržení délky trvání</td>
                    </tr>

                    <tr>
                        <td>1. – 364. den trvání produktu</td>
                        <td>3% z objemu participace</td>
                    </tr>

                    <tr>
                        <td>365. – 729. den trvání produktu</td>
                        <td>2% z objemu participace</td>
                    </tr>

                    <tr>
                        <td>730. – 1094. den trvání produktu</td>
                        <td>1% z objemu participace</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'Parameters4Trans'
    };
</script>

<style scoped></style>
