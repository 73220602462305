import axios from 'axios';

const baseURL = getApiUrl();

import Vue from 'vue';

import store from '@/store/index';

import EventBus from '@lb-world/core/public/plugins/eventBus';

import { getApiUrl, getAppUrlFormat, parseErrorMessageFromAPI } from '@/utils';

axios.defaults.baseURL = baseURL;
axios.defaults.headers['Content-Type'] = 'application/json; charset=UTF-8';

export const getInternalCode = response => {
    if (!response) {
        return '';
    }

    const internalCodes = {
        0x401: { code: 401 },
        0x403: { code: 403 }
    };

    return Object.keys(internalCodes).find(key => internalCodes[key].code === response.status);
};

axios.interceptors.response.use(
    response => {
        return response;
    },
    function(error) {
        const userToken = store.getters['auth/token'];

        if (onUnauthorizedLogout(error) && userToken) {
            Vue.$log.debug('[AUTH] Unauthorized API, running logout API');

            const parsedError = { errorKey: parseErrorMessageFromAPI(error) };
            parsedError.internalCode = getInternalCode(error?.response);

            EventBus.$emit('snackbar:show:danger', parsedError);

            store.dispatch('auth/user:clear').then(() => {
                window.location = getAppUrlFormat('world');
            });

            return;
        }

        if (error.response || error.request) {
            return Promise.reject(error.response);
        }
    }
);

const onUnauthorizedLogout = error => {
    return error.response && (error.response.status === 401 || error.response.status === 403);
};
