<template>
    <div>
        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template #item.productType="{ item }">
                {{ $t('productTypes.' + item.productId) }}
            </template>

            <template #item.id="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:contracts:detail',
                        params: { id: item.id, return: returnRoute }
                    }"
                >
                    {{ item.id }}
                </router-link>
            </template>

            <template #item.amount="{ item }">
                {{ formatAmount(item.purchaseAmount, item.currency) }} {{ item.currency }}
            </template>

            <template #item.openedAt="{ item }"> {{ formatTimestamp(item.openedAt) }} </template>

            <template #item.activationDate="{ item }"> {{ formatTimestamp(getActivationDate(item)) }} </template>

            <template #item.commissionTotal="{ item }"> {{ getCommissionTotal(item) }} </template>

            <template #item.commissionPaidOut="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:contracts:detail',
                        params: { id: item.id, filters: { limitTo: 'own' }, return: returnRoute }
                    }"
                >
                    {{ getCommissionPaidOut(item) }}
                </router-link>
            </template>

            <template #item.commissionToBePaidOut="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:contracts:detail',
                        params: {
                            id: item.id,
                            filters: { monthFrom: currentMonth, monthTo: currentMonth },
                            return: returnRoute
                        }
                    }"
                >
                    {{ getCommissionToBePaid(item) }}
                </router-link>
            </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners"></table-pagination>
    </div>
</template>

<script>
    import { core, pagination } from '@lb-world/core/public/components/tables/mixins';

    import moment from 'moment';

    export default {
        name: 'ClientDetailPortfolioTable',

        props: ['clientId'],

        mixins: [core, pagination],

        computed: {
            currentMonth() {
                return moment().format('YYYY-MM');
            },

            headers() {
                return [
                    {
                        text: this.$t('table.columns.id'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.productType'),
                        value: 'productType'
                    },
                    {
                        text: this.$t('table.columns.agreementDate'),
                        value: 'openedAt'
                    },
                    {
                        text: this.$t('table.columns.activationDate'),
                        value: 'activationDate'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount'
                    },
                    {
                        text: this.$t('table.columns.commissionTotal'),
                        value: 'commissionTotal'
                    },
                    {
                        text: this.$t('table.columns.commissionPaidOut'),
                        value: 'commissionPaidOut'
                    },
                    {
                        text: this.$t('table.columns.commissionToBePaidOut'),
                        value: 'commissionToBePaidOut'
                    }
                ];
            },

            returnRoute() {
                return { name: 'account:clients:detail', params: { id: this.clientId } };
            }
        },

        methods: {
            getActivationDate(item) {
                return item.activatedAt ?? item.openedAt;
            },

            getCommissionTotal(item) {
                if (item.commissionInfo) {
                    return (
                        this.formatAmount(item.commissionInfo?.totalCommission, item.commissionInfo?.currency) +
                        ' ' +
                        item.commissionInfo?.currency
                    );
                }

                return '-';
            },

            getCommissionPaidOut(item) {
                if (item.commissionInfo) {
                    return (
                        this.formatAmount(item.commissionInfo?.paidOut, item.commissionInfo?.currency) +
                        ' ' +
                        item.commissionInfo?.currency
                    );
                }

                return '-';
            },

            getCommissionToBePaid(item) {
                if (item.commissionInfo) {
                    return (
                        this.formatAmount(item.commissionInfo?.toBePaidOut, item.commissionInfo?.currency) +
                        ' ' +
                        item.commissionInfo?.currency
                    );
                }

                return '-';
            }
        }
    };
</script>

<style scoped></style>
