<template>
    <app-row>
        <app-registration-col whole-screen>
            <ul>
                <li>Jistý, předem známý výnos</li>
                <li>Vedení účtu u LBworld zdarma včetně intuitivního online portálu LBbroker</li>
                <li>Možnost výběru připisovaných úroků</li>
            </ul>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'AdvantageEfkStake'
    };
</script>

<style scoped></style>
