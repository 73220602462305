<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                Digi Renta je určena exkluzivně pouze držitelům EFK tokenů, které držitelé nakoupili v rámci privátního
                předprodeje na území České republiky.
            </p>

            <p class="mt-6">
                ReFork SE, emitent produktu Digi Renta, je držitelem know-how, patentových přihlášek a velké části
                produktového designu projektu ReFork.
            </p>

            <p class="mt-6">
                Vzhledem k tomu, že společnost ReFork Distribution je primárním výrobním závodem v rámci tokenomiky
                tokenu EFK a společnost ReFork SE by ráda využila předností EFK tokenu pro personifikaci produktů a
                následný prioritní prodej, nabízí společnost ReFork SE privátní odkup EFK tokenů v rámci produktu Digi
                Renta, aby prioritizovala pozici zákazníků se zájmem o produkty ReFork v rámci výrobního programu.
            </p>

            <p class="mt-6">
                ReFork SE využije nakoupené EFK tokeny pro zvýšení prodejních a marketingových aktivit a pro
                individualizaci produktových řad ReFork pro klienty ReFork SE.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'IntroductionDigiRent'
    };
</script>

<style scoped></style>
