<template>
    <app-row>
        <commission-payout-dialog :dialog="withdrawDialog" @payout:success="$emit('transactions:fetch')" />

        <app-registration-col whole-screen>
            <app-card>
                <app-title>{{ $t('bo.loadedCommission.title') }}</app-title>

                <app-loader v-if="!salesmanReportCurrentFetched" wrapper />

                <app-error v-else-if="salesmanReportCurrentError" horizontal fetching />

                <div
                    v-else
                    v-for="(balance, currency, index) in salesmanReportCurrent"
                    :key="currency"
                    :class="{ 'mb-3': !isLastItem(index, salesmanReportCurrent) }"
                >
                    <app-row>
                        <app-registration-col whole-screen>
                            <div class="loaded-commission">
                                <app-text-stack
                                    v-color:[currency].translucent
                                    class="loaded-commission__stack"
                                    :key="currency + '_' + type"
                                    v-for="type in commissionTypes"
                                >
                                    <template #key>
                                        {{ $t('bo.commissionTypes.' + type) }}
                                    </template>

                                    <router-link
                                        v-if="commissionRedirect"
                                        class="detail"
                                        :to="{
                                            name: 'account:commission:set',
                                            params: { filterParams: { currency, limitTo: type } }
                                        }"
                                    >
                                        {{ formatAmount(balance[type], currency) }}
                                    </router-link>

                                    <span v-else>
                                        {{ formatAmount(balance[type], currency) }}
                                    </span>

                                    <img
                                        :alt="currency"
                                        class="loaded-commission__currency-icon"
                                        :src="require('@lb-world/core/public/assets/crypto/' + currency + '.svg')"
                                    />

                                    <span :style="{ color: getCurrencyColor(currency) }">{{ currency }}</span>
                                </app-text-stack>
                            </div>
                        </app-registration-col>
                    </app-row>

                    <div v-if="currency === 'CZK'">
                        <app-error v-if="paymentDateError" fetching horizontal />
                        <app-text v-else>
                            <div class="payment-date">
                                <span
                                    class="payment-date__text"
                                    v-html="$t('bo.loadedCommission.payoutDate', { currency })"
                                />

                                <app-loader v-if="!paymentDateFetched" :size="16" />
                                <mark v-else> {{ nextPaymentDate }}.</mark>
                            </div>
                        </app-text>
                    </div>

                    <div v-else-if="currency === 'EFK'">
                        <app-error v-if="accountsError" horizontal fetching />

                        <app-row v-else>
                            <app-registration-col>
                                <div class="commission-account-payout">
                                    <div class="commission-account__balance">
                                        <p>
                                            {{ $t('bo.loadedCommission.currentBalance') }}
                                        </p>

                                        <app-loader :size="16" v-if="!accountsFetched" />
                                        <mark v-else>{{ commissionBalanceEFK }} EFK.</mark>
                                    </div>

                                    <button-wrapper justify="start" class="commission-account__button">
                                        <button-secondary
                                            full-width
                                            icon="arrow-alt-up"
                                            inline
                                            @click="openPayoutDialog"
                                        >
                                            {{ $t('bo.loadedCommission.payoutButton', { currency }) }}
                                        </button-secondary>
                                    </button-wrapper>
                                </div>
                            </app-registration-col>
                        </app-row>
                    </div>

                    <app-divider v-if="!isLastItem(index, salesmanReportCurrent)" />
                </div>

                <slot />
            </app-card>
        </app-registration-col>
    </app-row>
</template>

<script>
    import { mapGetters } from 'vuex';

    import Currencies from '@lb-world/core/public/utils/currencies';
    import CommissionPayoutDialog from '@/components/module-user/commissions/CommissionPayoutDialog';

    export default {
        name: 'LoadedCommissionOverview',

        components: { CommissionPayoutDialog },

        props: {
            commissionRedirect: Boolean
        },

        data: () => ({
            withdrawDialog: {
                params: {},
                open: false
            },

            commissionTypes: ['own', 'team', 'total']
        }),

        computed: {
            ...mapGetters('commission', ['accountsBalances', 'accountsError', 'accountsFetched']),

            ...mapGetters('reports', [
                'paymentDate',
                'paymentDateError',
                'paymentDateFetched',
                'salesmanReportCurrent',
                'salesmanReportCurrentError',
                'salesmanReportCurrentFetched'
            ]),

            commissionBalanceEFK() {
                const balance = this.accountsBalances?.['EFK'];

                return balance ? this.formatAmount(balance, 'efk') : 0 ?? 0;
            },

            nextPaymentDate() {
                const date = this.paymentDate?.nextPaymentTimestamp;

                return date ? this.formatTimestamp(date) : '-';
            }
        },

        methods: {
            isLastItem(index, object) {
                const objectLength = Object.keys(object)?.length;

                return objectLength > 0 ? index === objectLength - 1 : true;
            },
            openPayoutDialog() {
                this.withdrawDialog.params.balance = this.accountsBalances?.['EFK'];
                this.withdrawDialog.open = true;
            },
            getCurrencyColor(currency) {
                return Currencies.getColor(currency);
            }
        },

        mounted() {
            if (!this.paymentDateFetched) {
                this.$store.dispatch('reports/paymentDate:fetch');
            }

            if (!this.salesmanReportCurrentFetched) {
                this.$store.dispatch('reports/salesmanReport:current:fetch');
            }

            if (!this.accountsFetched) {
                this.$store.dispatch('commission/accounts:fetch');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .payment-date {
        display: flex;
        align-items: center;

        .payment-date__text {
            margin-right: spacing.$spacing-1;
            margin-bottom: 0 !important;
        }

        @include media.resolve('xs-only') {
            flex-direction: column;
        }
    }

    .commission-account-payout {
        display: flex;

        .commission-account__balance {
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0 !important;
                margin-right: spacing.$spacing-1;
            }
        }

        @include media.resolve('md-and-up') {
            align-items: center;

            .commission-account__button {
                margin-left: spacing.$spacing-3;
            }
        }

        @include media.resolve('sm-only') {
            flex-direction: column;

            .commission-account__balance p {
                text-align: start;

                margin-bottom: 0 !important;
            }
        }

        @include media.resolve('xs-only') {
            flex-direction: column;

            .commission-account__balance {
                flex-direction: column;

                p {
                    margin-right: 0;
                    margin-bottom: spacing.$spacing-1 !important;
                }
            }

            .commission-account__button {
                margin-top: spacing.$spacing-2;
            }
        }
    }

    .loaded-commission {
        display: flex;

        &::v-deep .loaded-commission__stack {
            margin-right: spacing.$spacing-2;
            padding: spacing.$spacing-3;
            border-radius: spacing.$spacing-2;
            width: 20%;
        }

        &::v-deep .loaded-commission__currency-icon {
            height: 14px;

            margin-left: spacing.$spacing-2;
            margin-right: spacing.$spacing-1;
        }

        @include media.resolve('md-only') {
            &::v-deep .loaded-commission__stack {
                width: 25%;
            }
        }

        @include media.resolve('sm-only') {
            &::v-deep .loaded-commission__stack {
                width: 33%;
            }
        }

        @include media.resolve('xs-only') {
            flex-direction: column;

            &::v-deep .loaded-commission__stack {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: spacing.$spacing-3;
                }
            }
        }
    }
</style>
