<template>
    <app-row>
        <app-registration-col whole-screen>
            <v-simple-table>
                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">Délka trvání vkladu</span></td>
                        <td>6 až 24 měsíců</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">Délka trvání</span></td>
                        <td>3 roky</td>
                    </tr>
                </tbody>
            </v-simple-table>

            <app-divider />

            <p class="mt-6">Úročení u produktu Stake EFK pro hodnoty pod 100 000 EFK.</p>

            <v-simple-table class="mt-6">
                <thead>
                    <tr>
                        <th>
                            Délka trvání vkladu
                        </th>

                        <th>
                            Úročení
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">6 měsíců</span></td>
                        <td>6 % EFK p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">12 měsíců</span></td>
                        <td>6 % EFK p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">24 měsíců</span></td>
                        <td>10 % EFK p.a.</td>
                    </tr>
                </tbody>
            </v-simple-table>

            <app-divider />

            <p class="mt-6">Úročení u produktu Stake EFK pro hodnoty nad 100 000 EFK (včetně).</p>

            <v-simple-table class="mt-6">
                <thead>
                    <tr>
                        <th>
                            Délka trvání vkladu
                        </th>

                        <th>
                            Úročení
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><span class="font-weight-bold">6 měsíců</span></td>
                        <td>8 % EFK p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">12 měsíců</span></td>
                        <td>10 % EFK p.a.</td>
                    </tr>

                    <tr>
                        <td><span class="font-weight-bold">24 měsíců</span></td>
                        <td>12 % EFK p.a.</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'ParametersEfkStake'
    };
</script>

<style scoped></style>
