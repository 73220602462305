<template>
    <app-row v-if="referralLink">
        <app-registration-col whole-screen>
            <v-expansion-panels>
                <app-expansion-panel :title="$t('bo.referralOverview.title')">
                    <app-text>{{ $t('bo.referralOverview.description') }}</app-text>

                    <app-row>
                        <app-registration-col field>
                            <app-text-field :value="referralLink" hide-hint readonly />
                        </app-registration-col>

                        <app-registration-col field>
                            <button-wrapper :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                                <button-secondary inline icon="copy" @click="copyReferralEmail">
                                    {{ $t('common.copy') }}
                                </button-secondary>
                            </button-wrapper>
                        </app-registration-col>
                    </app-row>
                </app-expansion-panel>
            </v-expansion-panels>
        </app-registration-col>
    </app-row>
</template>

<script>
    import { getWorldAppUrl } from '@/utils';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'UserReferralOverview',

        components: { AppExpansionPanel, AppTextField },

        computed: {
            userEmail() {
                return this.$store.getters['user/user:email'];
            },
            referralLink() {
                return getWorldAppUrl() + '/registration/user-details?referral=' + this.userEmail;
            }
        },

        methods: {
            copyReferralEmail() {
                navigator.clipboard
                    .writeText(this.referralLink)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.textCopied'));
                    })
                    .catch(() => {
                        EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                    });
            }
        }
    };
</script>

<style scoped></style>
