<template>
    <app-row>
        <app-registration-col class="pa-0" whole-screen>
            <app-flex>
                <app-flex-item class="four-items" v-for="product in products" :key="product.name">
                    <product-card :product="product" />
                </app-flex-item>
            </app-flex>
        </app-registration-col>
    </app-row>
</template>

<script>
    import ProductCard from '@/components/module-user/products/ProductCard';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';

    export default {
        name: 'ProductsContainer',

        components: { AppFlex, AppFlexItem, ProductCard },

        props: ['products']
    };
</script>

<style scoped></style>
