import { render, staticRenderFns } from "./DocumentCategory.vue?vue&type=template&id=7091b968&scoped=true&"
import script from "./DocumentCategory.vue?vue&type=script&lang=js&"
export * from "./DocumentCategory.vue?vue&type=script&lang=js&"
import style0 from "./DocumentCategory.vue?vue&type=style&index=0&id=7091b968&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7091b968",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VExpansionPanels,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
