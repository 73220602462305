<template>
    <app-row>
        <app-registration-col whole-screen>
            <app-card>
                <app-title>{{ category.name }}</app-title>

                <app-text>{{ category.description }}</app-text>
            </app-card>

            <v-expansion-panels :disabled="fileCount <= 0">
                <app-expansion-panel
                    icon-color="primary"
                    icon="folder-open"
                    :title="fileCount + ' ' + $tc('bo.documents.files', fileCount)"
                    title-size="highlight"
                >
                    <v-list>
                        <v-list-item>
                            <v-list-item-content class="inner ml-sm-6 ml-2">
                                <div v-for="(file, index) in category.files" :key="file.id" class="subitem__inner">
                                    <div class="d-flex">
                                        <font-awesome-icon
                                            :icon="['fad', 'folder-open']"
                                            v-color:primary
                                            class="mt-1"
                                        />

                                        <div>
                                            <v-list-item-title>
                                                <p class="popover-category">{{ file.name }}</p>
                                            </v-list-item-title>

                                            <v-list-item-subtitle class="mt-1">
                                                <p>{{ file.description }}</p>
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle class="mt-1">
                                                <button-secondary
                                                    :loading="fileButtonsLoading[file.id]"
                                                    icon="download"
                                                    inline
                                                    @click="downloadFile(file.id)"
                                                >
                                                    {{ $t('bo.documents.download') }}
                                                </button-secondary>
                                            </v-list-item-subtitle>
                                        </div>
                                    </div>

                                    <app-divider
                                        v-if="!isLastItem(index, category.files)"
                                        :width-margin="0"
                                        :bottom-margin="0"
                                    />
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </app-expansion-panel>
            </v-expansion-panels>
        </app-registration-col>
    </app-row>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'DocumentCategory',

        components: { AppExpansionPanel },

        props: ['category'],

        data: () => ({
            fileButtonsLoading: {}
        }),

        computed: {
            fileCount() {
                return this.category.files?.length ?? 0;
            }
        },

        methods: {
            isLastItem(index, data) {
                if (data?.length > 0) {
                    return index === data.length - 1;
                }

                return false;
            },
            downloadFile(fileId) {
                this.fileButtonsLoading[fileId] = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('storage/file:download', fileId)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.fileButtonsLoading[fileId] = false;
                    });
            }
        },

        mounted() {
            for (const index in this.category.files) {
                const file = this.category.files[index];

                if (file) {
                    this.$set(this.fileButtonsLoading, file.id, false);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .subitem__inner {
        padding: 16px 0px 0;
    }

    .v-list-item__content.inner {
        padding: 0 16px 16px;
    }

    .v-list-item__content {
        padding: 16px 0 16px;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        padding: 0 16px;
        white-space: normal;
    }
    .v-sheet.v-list {
        padding: 0;
    }
    .v-list-item {
        padding: 0;
    }
    .v-list-item__title {
        text-align: start;
    }

    .v-list-item__subtitle p {
        text-align: start !important;
    }

    .ghost {
        opacity: 0.35;
        background: colors.$color-primary-50;
    }
</style>
