<template>
    <v-card outlined class="app-card" :class="{ noMargin, selected, fullWidth, increasedShadow, textWhite, table }">
        <v-card-title v-if="$slots['title']" :class="[{ left }, 'title-' + titleHeight]" class="title">
            <app-row :class="{ title__standalone: !$slots['top-button'] }">
                <app-registration-col whole-screen class="pb-0">
                    <p>
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </p>
                </app-registration-col>
            </app-row>

            <app-row justify="end" v-if="$slots['top-button']">
                <app-registration-col whole-screen class="pb-0">
                    <button-wrapper justify="end">
                        <slot name="top-button"></slot>
                    </button-wrapper>
                </app-registration-col>
            </app-row>
        </v-card-title>

        <app-row v-if="$slots['default']">
            <v-card-text :class="{ left }">
                <app-row>
                    <app-registration-col whole-screen :class="{ 'py-0': narrow, 'pa-0': dialog }">
                        <slot></slot>
                    </app-registration-col>
                </app-row>
            </v-card-text>
        </app-row>

        <app-row v-if="$slots['button-primary'] || $slots['button-secondary']">
            <app-registration-col class="pt-0" whole-screen>
                <app-row :justify="left ? 'start' : 'center'">
                    <app-registration-col whole-screen>
                        <button-wrapper class="app-card__buttons" :justify="left ? 'start' : 'center'">
                            <slot v-if="$slots['button-primary'] && !buttonSwitch" name="button-primary" />
                            <slot v-if="$slots['button-secondary']" name="button-secondary" />
                            <slot v-if="$slots['button-primary'] && buttonSwitch" name="button-primary" />
                        </button-wrapper>
                    </app-registration-col>
                </app-row>
            </app-registration-col>
        </app-row>
    </v-card>
</template>

<script>
    export default {
        name: 'AppCard',

        props: {
            titleHeight: {
                type: String,
                validator: n => ['headline', 'item'].includes(n),
                default: 'headline'
            },
            title: String,
            left: Boolean,
            noMargin: Boolean,
            selected: Boolean,
            fullWidth: Boolean,
            buttonSwitch: Boolean,
            textWhite: Boolean,
            increasedShadow: Boolean,
            table: Boolean,
            narrow: Boolean,
            dialog: Boolean
        }
    };
</script>

<style lang="scss"></style>
