import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import TableDataset from '@lb-world/core/public/models/store/TableDataset';

import CustomersRepository from '@lb-world/core/public/api/repositories/CustomersRepository';
import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';
import AMLRepository from '@lb-world/core/public/api/repositories/AMLRepository';
import ProductsRepository from '@lb-world/core/public/api/repositories/ProductsRepository';

const state = {
    clientsList: new TableDataset(),
    clientsTree: new BaseDataset()
};

const getReferralsForDirectNodes = (acc, node, parent) => {
    acc.push({ ...node.user, parent });

    for (const child of node.children) {
        getReferralsForDirectNodes(acc, child, node.user?.id);
    }
};

const sortReferrals = (a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();

    for (let index = 0; aName[index] && bName[index]; index++) {
        if (aName[index] < bName[index]) {
            return -1;
        } else if (aName[index] > bName[index]) {
            return 1;
        }
    }

    return 0;
};

const getters = {
    list: state => state.clientsList.getItems(),
    'list:fetched': state => state.clientsList.fetched,
    'list:error': state => state.clientsList.fetchError,
    'list:pages': state => state.clientsList.getPages(),
    'list:filter': state => state.clientsList.filter,

    'tree:user': state => state.clientsTree.data?.user,
    'tree:fetched': state => state.clientsTree.fetched,
    'tree:error': state => state.clientsTree.fetchError,
    'tree:children': state => state.clientsTree.data?.children,
    'tree:selectBox': (state, getters) => {
        const rootGetter = getters['tree:user'];
        const referralsGetter = getters['tree:referrals'];

        if (!rootGetter || !referralsGetter) {
            return [];
        }

        const referrals = referralsGetter
            .map(user => ({
                name: user.name + ' ' + user.surname,
                value: user.id
            }))
            .sort(sortReferrals);

        referrals.splice(0, 0, { name: rootGetter.name + ' ' + rootGetter.surname, value: rootGetter.id });

        return referrals;
    },
    'tree:referrals': state => {
        const acc = [];

        if (state.clientsTree.fetched && !state.clientsTree.fetchError) {
            for (const direct of state.clientsTree.data?.children) {
                getReferralsForDirectNodes(acc, direct, state.clientsTree.data.user?.id);
            }
        }

        return acc;
    }
};

const actions = {
    'tree:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CustomersRepository.getSalesmanTree(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'clientsTree', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'clientsTree');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'clientsTree');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'list:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'clientsList');

            resolve();
        });
    },
    'list:fetch': ({ commit, rootGetters }, { page = 1, filters, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CustomersRepository.getList({
                data: { userId: rootGetters['user/user:id'] },
                columnOptions: { filters, sorts },
                apiOptions: { page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'clientsList', data: response.data, filters });

                        resolve();
                    } else {
                        commit('error', 'clientsList');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'clientsList');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:fetch': (_, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            UserRepository.get(userId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:aml:fetch': (_, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUserState(userId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'detail:portfolio:fetch': (_, { page, user }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ProductsRepository.getPA({ data: { user }, apiOptions: { page, expand: 'commission' } })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data, filter }) => {
        state[key].storeData(data, filter);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
