<template>
    <app-row>
        <app-registration-col whole-screen>
            <app-card>
                <app-title>{{ $t('bo.newsOverview.title') }}</app-title>

                <app-loader v-if="!parametersFetched" wrapper />

                <app-error v-else-if="parametersError" horizontal fetching />

                <app-row v-else>
                    <app-registration-col whole-screen class="pa-0">
                        <app-flex>
                            <app-flex-item>
                                <app-text-stack :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                                    <template #key>
                                        {{ $t('bo.newsOverview.throughput.efkPurchase') }}
                                    </template>

                                    {{ getParameterByCurrency('throughput__efk_purchase') }}
                                </app-text-stack>
                            </app-flex-item>

                            <app-flex-item>
                                <app-text-stack :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                                    <template #key>
                                        {{ $t('bo.newsOverview.throughput.efkLiquidity') }}
                                    </template>

                                    {{ getParameterByCurrency('throughput__efk_liquidity') }}
                                </app-text-stack>
                            </app-flex-item>
                        </app-flex>
                    </app-registration-col>
                </app-row>
            </app-card>
        </app-registration-col>
    </app-row>
</template>

<script>
    import { mapGetters } from 'vuex';

    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';

    export default {
        name: 'NewsOverview',

        components: { AppFlexItem, AppFlex },

        computed: mapGetters('dashboard', ['parameters', 'parametersFetched', 'parametersError']),

        methods: {
            getParameterByCurrency(currency) {
                const param = this.parameters[currency];

                if (param === null || param === undefined) {
                    return '--';
                }

                return param?.value + ' ' + param?.unit;
            },

            fetchParameters() {
                this.$store.dispatch('dashboard/parameters:clear').then(() => {
                    this.$store.dispatch('dashboard/parameters:fetch');
                });
            }
        },

        mounted() {
            if (!this.parametersFetched) {
                this.fetchParameters();
            }
        }
    };
</script>

<style scoped></style>
