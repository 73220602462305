export const CURRENT_APP = 'finance';
export const CURRENT_APP_URL = 'https://lbfinance.eu';
export const CURRENT_APP_DEV_URL = 'https://dev.lbfinance.eu';
export const CURRENT_APP_VERSION = '1.16.1';

// This constant is automatically detected by @lb-world/core package
export const APP_PRIMARY_COLOR = '#398bbf';

export const DOCUMENT_PRODUCT_SPECS_DIGI_RENT = 'lbbroker-product-specs--digi-rent';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE = 'lbbroker-product-specs--stake-efk';
export const DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY = 'lbbroker-product-specs--liquidity-time';
export const DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE = 'lbbroker-product-specs--efk-purchase';
export const DOCUMENT_PRODUCT_SPECS_4TRANS = 'lbbroker-product-specs--4trans';
