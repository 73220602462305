<template>
    <app-container decreased-page-padding unlimited-width>
        <app-row v-if="$vuetify.breakpoint.lgAndUp">
            <user-tree-layout-col structure>
                <user-tree-structure
                    @update:active="setActiveNode"
                    :requestedNode="activeNode"
                    :opened-nodes="parentNodes"
                    :tree-children="treeChildren"
                    :tree-fetched="treeFetched"
                    :tree-error="treeError"
                    :tree-user="treeUser"
                />
            </user-tree-layout-col>

            <user-tree-layout-col detail>
                <user-detail-overview
                    :user="user"
                    floating
                    display-referral-link
                    display-received-commission
                    :on-back-pressed="onBackPressed"
                    :commission-account="activeNodeCA"
                    :tree-fetched="treeFetched"
                    :tree-error="treeError"
                    :received-commission="receivedCommission"
                />
            </user-tree-layout-col>
        </app-row>

        <div v-else>
            <user-detail-overview
                :user="user"
                display-referral-link
                display-received-commission
                :on-back-pressed="onBackPressed"
                :commission-account="activeNodeCA"
                :tree-fetched="treeFetched"
                :tree-error="treeError"
                :received-commission="receivedCommission"
            />

            <user-tree-structure
                @update:active="setActiveNode"
                :requestedNode="activeNode"
                :opened-nodes="parentNodes"
                :tree-children="treeChildren"
                :tree-fetched="treeFetched"
                :tree-error="treeError"
                :tree-user="treeUser"
            />
        </div>
    </app-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import UserTreeStructure from '@lb-world/user-tree/public/components/UserTreeStructure';
    import UserDetailOverview from '@lb-world/user-tree/public/components/UserDetailOverview';
    import UserTreeLayoutCol from '@lb-world/user-tree/public/components/UserTreeLayoutCol';
    import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

    export default {
        name: 'Coworkers',

        components: {
            UserTreeLayoutCol,
            UserDetailOverview,
            UserTreeStructure
        },

        data: () => ({
            activeNode: null,
            activeNodeCA: null,
            parentNodes: [],

            receivedCommission: new BaseDataset()
        }),

        computed: {
            ...mapGetters('clients', {
                treeError: 'tree:error',
                treeFetched: 'tree:fetched',
                treeUser: 'tree:user',
                treeChildren: 'tree:children',
                treeReferrals: 'tree:referrals'
            }),

            requestedNode() {
                return this.$route.params?.id ?? null;
            },

            user() {
                return this.activeNode ? this.treeReferrals?.find(node => node.id === this.activeNode) : null ?? null;
            }
        },

        methods: {
            fetchCommissionAccount() {
                this.activeNodeCA = null;

                if (this.user?.id) {
                    this.$store.dispatch('commission/accounts:detail:fetch', this.user.id).then(commissionAccount => {
                        this.activeNodeCA = commissionAccount;
                    });
                }
            },

            onBackPressed() {
                const returnRoute = this.$route.params?.return;

                this.$router.push(returnRoute ? returnRoute : { name: 'account:contracts' });
            },

            setActiveNode(nodes) {
                if (nodes && nodes[0]) {
                    this.activeNode = nodes[0];
                }
            },

            getNodeParents(parents, nodeId) {
                const parentNode = this.treeReferrals.find(u => u.id === nodeId);

                if (parentNode) {
                    parents.push(parentNode?.id);

                    this.getNodeParents(parents, parentNode?.parent);
                }
            },

            fetchReceivedCommission() {
                this.receivedCommission.clearData();

                this.$store
                    .dispatch('reports/salesmanReport:userDetail:fetch', this.user?.id)
                    .then(receivedCommission => {
                        this.receivedCommission.storeData(receivedCommission?.commissions);
                    })
                    .catch(() => {
                        this.receivedCommission.storeError();
                    });
            }
        },

        watch: {
            user() {
                this.fetchCommissionAccount();
                this.fetchReceivedCommission();
            },

            activeNode() {
                this.getNodeParents(this.parentNodes, this.activeNode);
            }
        },

        mounted() {
            this.activeNode = this.requestedNode;

            if (!this.treeFetched) {
                this.$store.dispatch('clients/tree:fetch').catch(error => {
                    EventBus.$emit('snackbar:show:danger', error);
                });
            }
        }
    };
</script>

<style scoped></style>
