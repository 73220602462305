<template>
    <v-menu v-if="!hide" v-model="menu" offset-y :nudge-width="150" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <button-secondary
                v-on="on"
                inline
                class="select-box-activator user-info-panel mr-md-9 mr-3"
                @click="menu = !menu"
            >
                <span class="user-info-panel--email d-flex align-center">
                    <font-awesome-icon
                        size="lg"
                        :icon="['fad', 'user-circle']"
                        class="user-info-panel--user-icon mr-2"
                    />

                    <span v-if="$vuetify.breakpoint.mdAndUp" v-color:black class="mr-3">
                        {{ username }}
                    </span>

                    <font-awesome-icon
                        v-color:black
                        size="sm"
                        :icon="['fas', 'chevron-down']"
                        class="user-info-panel--user-icon"
                    ></font-awesome-icon>
                </span>
            </button-secondary>
        </template>

        <app-card left class="pa-4">
            <app-text-stack>
                <template #key>
                    {{ $t('topPanel.userId') }}
                </template>

                <div class="d-flex align-center">
                    <span class="mr-4">{{ userId }}</span>

                    <button-secondary small @click="redirectToAccount" inline>
                        <app-logo logo-only size="xs" class="mr-1"></app-logo>
                        <span v-color:world>{{ $t('topPanel.displayProfileButton') }}</span>
                    </button-secondary>
                </div>
            </app-text-stack>

            <hr class="mt-3" />

            <app-text-stack class="mt-6">
                <template #key>
                    {{ $t('topPanel.username') }}
                </template>

                <div class="menu-user-email">
                    <span class="mr-4">{{ username }}</span>

                    <button-primary small :loading="logoutButton.loading" @click="signOut">
                        {{ $t('topPanel.logOutButton') }}
                    </button-primary>
                </div>
            </app-text-stack>

            <hr class="mt-3" />

            <app-text-stack class="mt-6">
                <template #key>
                    {{ $t('topPanel.version') }}
                </template>

                <span>{{ appVersion }}</span>
            </app-text-stack>
        </app-card>
    </v-menu>
</template>

<script>
    import AppCard from '@/components/ui/AppCard';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getAppUrlFormat, getWorldSignInUrlFormat } from '@/utils';

    import { CURRENT_APP_VERSION } from '@/constants';

    export default {
        name: 'UserInfoPanel',

        components: {
            AppCard
        },

        data: () => ({
            appVersion: CURRENT_APP_VERSION,

            menu: false,

            logoutButton: {
                loading: false
            }
        }),

        props: {
            hide: {
                type: Boolean
            },
            userId: {
                type: String
            },
            username: {
                type: String
            }
        },

        methods: {
            redirectToAccount() {
                window.open(getAppUrlFormat('world', '/account/account'));
            },
            signOut() {
                this.logoutButton.loading = true;

                this.$store
                    .dispatch('auth/user:logout')
                    .then(() => {
                        window.location = getWorldSignInUrlFormat(false);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.logoutButton.loading = false;
                    });
            }
        }
    };
</script>

<style lang="scss">
    .user-info-panel {
        position: absolute !important;
        right: 0;

        .user-info-panel--user-icon {
            color: colors.$color-primary;
        }

        .user-info-panel--email {
            @include text.TextBold-16-100;
        }

        button + button {
            margin-left: spacing.$spacing-3 !important;
        }
    }

    .menu-user-email {
        display: flex;

        @include media.resolve('sm-and-down') {
            flex-direction: column;

            button {
                margin-top: spacing.$spacing-3;
            }
        }

        @include media.resolve('md-and-up') {
            align-items: center;
        }
    }
</style>
