import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);

/* Account tab icons */
import {
    faHistory,
    faFileAlt,
    faMoneyCheck,
    faLeaf,
    faSignOut,
    faWallet,
    faExchange,
    faArrowAltDown,
    faArrowAltUp,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowsAlt,
    faSearchPlus,
    faSearchMinus,
    faExclamationCircle,
    faCopy,
    faCogs,
    faFolderOpen,
    faDownload,
    faAlignJustify,
    faArrowAltCircleLeft,
    faUserTie,
    faHome,
    faUserAlt,
    faThumbsUp,
    faSlidersH,
    faFileExport,

    //System
    faUserCircle,
    faMapMarkedAlt,
    faWrench,
    faBomb,

    // Sections
    faUsers,
    faTh,
    faTreeAlt,
    faList,
    faCoin,
    faHandshakeAlt,
    faBoxAlt
} from '@fortawesome/pro-duotone-svg-icons';

import { faBars } from '@fortawesome/pro-regular-svg-icons';

import {
    faCheckCircle,
    faTimesCircle,
    faChevronDown,
    faChevronRight,
    faPlus,
    faMinus,
    faGlobe,
    faWindowMinimize,
    faTimes,
    faAlignLeft
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faHistory,
    faLeaf,
    faMoneyCheck,
    faUserCircle,
    faFileAlt,
    faExchange,
    faWallet,
    faCheckCircle,
    faTimesCircle,
    faBars,
    faChevronDown,
    faSignOut,
    faChevronDown,
    faArrowAltDown,
    faArrowAltUp,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowsAlt,
    faSearchPlus,
    faSearchMinus,
    faPlus,
    faMinus,
    faGlobe,
    faWindowMinimize,
    faExclamationCircle,
    faTimes,
    faCopy,
    faCogs,
    faFolderOpen,
    faDownload,
    faAlignJustify,
    faArrowAltCircleLeft,
    faUserTie,
    faHome,
    faUserAlt,
    faChevronRight,
    faThumbsUp,
    faSlidersH,
    faAlignLeft,
    faFileExport,

    // Sections
    faTh,
    faUsers,
    faTreeAlt,
    faList,
    faCoin,
    faHandshakeAlt,
    faBoxAlt,

    faMapMarkedAlt,
    faWrench,
    faBomb
);
