<template>
    <app-row>
        <app-registration-col whole-screen>
            <app-card>
                <app-title>{{ $t('bo.expiringProductsOverview.title') }}</app-title>

                <expiring-contracts-table
                    class="mt-3"
                    :loading="!expiringContractsFetched"
                    :items="expiringContracts"
                    :pages="expiringContractsPages"
                    :fetch-error="expiringContractsError"
                    :filters.sync="contractsTable.filters"
                    :custom-default="contractsTable.customDefaultFilters"
                    :pre-applied="contractsTable.preAppliedFilters"
                    @pagination:change="fetchExpiringContracts"
                    @filter:update="filterExpiringContracts"
                    @filter:reset="filterExpiringContracts"
                />
            </app-card>
        </app-registration-col>
    </app-row>
</template>

<script>
    import ExpiringContractsTable from '@/components/ui/tables/ExpiringContractsTable';

    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'ExpiringProductsOverview',

        components: { ExpiringContractsTable },

        computed: {
            ...mapGetters('contracts', [
                'expiringContracts',
                'expiringContractsFetched',
                'expiringContractsError',
                'expiringContractsPages',
                'expiringContractsFilter'
            ])
        },

        data: () => ({
            contractsTable: {
                page: 1,
                preAppliedFilters: null,
                customDefaultFilters: {
                    commissionOwn: null
                },
                filters: {
                    commissionOwn: null
                }
            }
        }),

        methods: {
            fetchExpiringContracts(page) {
                this.contractsTable.page = page;

                this.$store.dispatch('contracts/expiringContracts:clear').then(() => {
                    this.$store
                        .dispatch('contracts/expiringContracts:fetch', {
                            page,
                            filters: this.contractsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            filterExpiringContracts() {
                this.contractsTable.page = 1;

                this.$store.dispatch('contracts/expiringContracts:clear').then(() => {
                    this.$store
                        .dispatch('contracts/expiringContracts:fetch', {
                            page: this.contractsTable.page,
                            filters: this.contractsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.expiringContractsFilter) {
                this.$set(this.contractsTable, 'preAppliedFilters', this.expiringContractsFilter);
            }

            if (!this.expiringContractsFetched) {
                this.fetchExpiringContracts();
            }
        }
    };
</script>

<style scoped></style>
