<template>
    <div>
        <app-row>
            <app-registration-col>
                <app-switch-field
                    :centered="$vuetify.breakpoint.xsOnly"
                    hide-hint
                    v-model="filters.commissionOwn"
                    :label="$t('filters.commissionOwn')"
                    @change="$emit('filter:update')"
                />
            </app-registration-col>
        </app-row>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            disable-sort
            :loading="loading"
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:item.id="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:contracts:detail',
                        params: { id: item.id, return: { name: 'account:dashboard' } }
                    }"
                >
                    {{ item.id }}
                </router-link>
            </template>

            <template v-slot:item.productId="{ item }">
                {{ $t('productTypes.' + item.productId) }}
            </template>

            <template v-slot:item.expiryDate="{ item }">
                {{ formatTimestamp(item.shouldBeClosedAt) }}
            </template>

            <template v-slot:item.client="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:clients:detail',
                        params: { id: item.user.id, return: { name: 'account:dashboard' } }
                    }"
                >
                    {{ getClient(item.user) }}
                </router-link>
            </template>

            <template v-slot:item.referral="{ item }">
                <router-link
                    v-if="hasReferralAvailable(item)"
                    class="detail"
                    :to="{
                        name: 'account:coworkers',
                        params: { id: getReferralId(item), return: { name: 'account:dashboard' } }
                    }"
                >
                    {{ getReferralName(item) }}
                </router-link>

                <span v-else>{{ getReferralName(item) }}</span>
            </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';

    import { PTFactory } from '@lb-world/core/public/static/productTypes';
    import { mapGetters } from 'vuex';

    import { createHeaderItem } from '@lb-world/core/public/components/tables/utils';

    import AppSwitchField from '@/components/ui/form/AppSwitchField';

    export default {
        name: 'ExpiringContractsTable',

        components: { AppSwitchField },

        mixins: [core, pagination, filtering],

        data: () => ({
            productTypes: PTFactory.getUserRestricted(),

            calendar: {
                monthFrom: false,
                monthTo: false
            }
        }),

        computed: {
            ...mapGetters('clients', {
                treeFetched: 'tree:fetched',
                treeReferrals: 'tree:selectBox'
            }),

            userId() {
                return this.$store.getters['user/user:id'];
            },

            headers() {
                return [
                    createHeaderItem(this.$t('table.columns.expiryDate'), 'expiryDate'),
                    createHeaderItem(this.$t('table.columns.client'), 'client'),
                    createHeaderItem(this.$t('table.columns.referral'), 'referral'),
                    createHeaderItem(this.$t('table.columns.productType'), 'productId'),
                    createHeaderItem(this.$t('table.columns.productAccountId'), 'id')
                ];
            }
        },

        methods: {
            hasReferralAvailable(item) {
                const referralId = this.getReferralId(item);

                const isReferralAvailable = this.treeReferrals.find(referral => referral.value === referralId);
                const isMyAccount = this.userId === referralId;

                return isReferralAvailable && !isMyAccount;
            },

            getReferralId(item) {
                return item.user?.referral?.id;
            },

            getReferralName(item) {
                const referral = item.user?.referral;

                if (referral) {
                    return referral.name + ' ' + referral.surname;
                } else {
                    return '-';
                }
            },

            getClient(user) {
                return user?.name && user?.surname ? user.name + ' ' + user.surname : '-';
            },

            getReferral(user) {
                return user?.referral?.name && user?.referral?.surname
                    ? user.referral.name + ' ' + user.referral.surname
                    : '-';
            }
        },

        mounted() {
            if (!this.treeFetched) {
                this.$store.dispatch('clients/tree:fetch');
            }
        }
    };
</script>

<style scoped></style>
