var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","multi-sort":"","loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.items.length},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.productId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('productTypes.' + item.productId))+" ")]}},{key:"item.openedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.openedAt))+" ")]}},{key:"item.activationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.activatedAt))+" ")]}},{key:"item.productAccountId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"detail",attrs:{"to":{
                    name: 'account:contracts:detail',
                    params: { id: item.productAccountId, return: { name: 'account:commission:set' } }
                }}},[_vm._v(" "+_vm._s(item.productAccountId)+" ")])]}},{key:"item.client",fn:function(ref){
                var item = ref.item;
return [(item.client)?_c('span',[_vm._v(_vm._s(_vm.getClientName(item.client)))]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.referral",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReferralName(item))+" ")]}},{key:"item.actualShare",fn:function(ref){
                var item = ref.item;
return [(item.actualShare)?_c('span',[_vm._v(" "+_vm._s(item.actualShare)+"% ")]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.amount, item.currency))+" "+_vm._s(item.currency)+" ")]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(_vm.fetchError)?_c('app-error',{attrs:{"horizontal":"","fetching":"","no-padding":""}}):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }