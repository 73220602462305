import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import CommissionParamsRepository from '@lb-world/core/public/api/repositories/CommissionParamsRepository';

const state = {
    parameters: new BaseDataset()
};

const getters = {
    parameters: state => state.parameters.data,
    parametersFetched: state => state.parameters.fetched,
    parametersError: state => state.parameters.fetchError
};

const actions = {
    'parameters:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CommissionParamsRepository.get()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'parameters', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'parameters');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'parameters');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'parameters:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'parameters');

            resolve();
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
