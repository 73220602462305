<template>
    <app-row>
        <app-registration-col whole-screen>
            <p>
                LBworld představuje produkt EFK Likvidita, který je určen občanům ke směně EFK tokenů skrze LBworld na
                CZK.
            </p>

            <p class="mt-6">
                LBworld spolupracuje se společností, která vlastní a provozuje roboty pro obchodování na tokenových
                burzách. Nabízí tedy možnost zlikvidnění EFK tokenů vůči USDt a následně přes směnárnu na CZK bez
                nutnosti registrace na jednotlivé tokenové burzy, kde je EFK token obchodován.
            </p>

            <p class="mt-6">
                Cílem LBworld je maximalizovat zisk pro klienta. A co nejvíc ochránit rozvíjející se trh EFK tokenu tak,
                aby jeho hodnota (cena vůči USDt) mohla růst.
            </p>

            <p class="mt-6">
                Produkt lze ovládat přes prostředí LBworld v sekci LBbroker (výběry, nastavení hodnot, nastavení
                prodloužení aj.).
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'IntroductionEfkLiquidity'
    };
</script>

<style scoped></style>
