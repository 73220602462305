<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-text-field
            :type="fieldType"
            :step="step"
            outlined
            v-model="innerValue"
            v-bind="$attrs"
            v-on="$listeners"
            :hide-details="hideHint"
            :persistent-hint="optional"
            :hint="optional ? $t('forms.optionalField') : ''"
            :error-messages="errors"
            :readonly="readonly"
        >
            <template #prepend-inner v-if="$slots['prepend']">
                <span class="v-input__prepend-append-text">
                    <slot name="prepend"> </slot>
                </span>
                <div class="v-input__prepend-append-divider v-input__prepend-divider"></div>
            </template>

            <template #append v-if="$slots['append']">
                <div class="v-input__prepend-append-divider v-input__append-divider"></div>

                <span class="v-input__prepend-append-text v-input__append-text">
                    <slot name="append"> </slot>
                </span>
            </template>

            <template #append v-else-if="!$slots['append'] && type === 'password'">
                <div class="v-input__prepend-append-divider v-input__append-divider"></div>

                <span class="v-input__prepend-append-text v-input__append-text cursor-pointer">
                    <font-awesome-icon @click="displayPassword" :icon="['fad', 'eye']" />
                </span>
            </template>
        </v-text-field>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppTextField',

        components: {
            ValidationProvider
        },

        data: () => ({
            innerValue: '',
            fieldType: ''
        }),

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },
            value: { type: null },
            readonly: {
                type: Boolean
            },
            optional: {
                type: Boolean,
                default: false
            },
            hideHint: {
                type: Boolean
            },
            type: {
                type: String,
                default: 'text'
            },
            step: {
                type: Number
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        methods: {
            displayPassword() {
                this.fieldType = this.fieldType === 'password' ? 'type' : 'password';
            }
        },

        created() {
            if (this.value) {
                this.innerValue = this.value;
            }

            this.fieldType = this.type;
        }
    };
</script>

<style lang="scss" scoped></style>
