import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import CommissionReportsRepository from '@lb-world/core/public/api/repositories/CommissionReportsRepository';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import TableDataset from '@lb-world/core/public/models/store/TableDataset';

const state = {
    paymentDate: new BaseDataset(),
    salesmanReportCurrent: new BaseDataset(),

    srDynamicCommissions: new BaseDataset(),
    srDynamicTransactions: new TableDataset()
};

const getters = {
    paymentDate: state => state.paymentDate.data,
    paymentDateFetched: state => state.paymentDate.fetched,
    paymentDateError: state => state.paymentDate.fetchError,

    salesmanReportDynamicCommissions: state => state.srDynamicCommissions.data,
    salesmanReportDynamicCommissionsFetched: state => state.srDynamicCommissions.fetched,
    salesmanReportDynamicCommissionsError: state => state.srDynamicCommissions.fetchError,

    salesmanReportDynamicTransactions: state => state.srDynamicTransactions.getItems(),
    salesmanReportDynamicTransactionsFetched: state => state.srDynamicTransactions.fetched,
    salesmanReportDynamicTransactionsError: state => state.srDynamicTransactions.fetchError,
    salesmanReportDynamicTransactionsPages: state => state.srDynamicTransactions.getPages(),
    salesmanReportDynamicTransactionsFilter: state => state.srDynamicTransactions.filter,

    salesmanReportCurrent: state => state.salesmanReportCurrent.data?.commissions,
    salesmanReportCurrentFetched: state => state.salesmanReportCurrent.fetched,
    salesmanReportCurrentError: state => state.salesmanReportCurrent.fetchError
};

const actions = {
    'paymentDate:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getPaymentDate()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'paymentDate', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'paymentDate');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'paymentDate');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'paymentDate:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'paymentDate');

            resolve();
        });
    },

    'salesmanReport:current:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'salesmanReportCurrent');

            resolve();
        });
    },
    'salesmanReport:current:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getSalesman({ data: { userId: rootGetters['user/user:id'] } })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'salesmanReportCurrent', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'salesmanReportCurrent');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'salesmanReportCurrent');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'salesmanReport:dynamic:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'srDynamicCommissions');
            commit('clear', 'srDynamicTransactions');

            resolve();
        });
    },

    'salesmanReport:dynamic:fetch': ({ commit, rootGetters }, { page, filters, sorts }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getSalesman({
                data: { userId: rootGetters['user/user:id'] },
                columnOptions: { filters, sorts },
                apiOptions: { expand: true, page }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data?.commissions && response.data?.transactions) {
                        commit('store', { key: 'srDynamicCommissions', data: response.data?.commissions });
                        commit('store', { key: 'srDynamicTransactions', data: response.data?.transactions, filters });

                        resolve();
                    } else {
                        commit('error', 'srDynamicCommissions');
                        commit('error', 'srDynamicTransactions');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'srDynamicCommissions');
                    commit('error', 'srDynamicTransactions');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'salesmanReport:dynamic:export': ({ rootGetters }, { format, filters, sorts, binary }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const binaryOptions = binary ? { responseType: 'blob' } : null;

        return new Promise((resolve, reject) => {
            CommissionReportsRepository.getSalesman({
                data: { userId: rootGetters['user/user:id'] },
                columnOptions: {
                    filters,
                    sorts
                },
                apiOptions: {
                    exportTo: format,
                    binaryOptions
                }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.status === 204) {
                        reject('export_no_data');
                    } else if (response.data) {
                        const searchKeyword = 'filename="';

                        const filenameHeader = response.headers['content-disposition'];
                        const filenameIndex = filenameHeader?.indexOf(searchKeyword);

                        let filename = 'lb-world_export.csv';

                        if (filenameIndex && filenameIndex !== -1) {
                            filename = filenameHeader.slice(filenameIndex + searchKeyword.length, -1);
                        }

                        resolve({ data: response.data, filename });
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'salesmanReport:userDetail:fetch': ({ rootGetters }, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const filters = {
                viewAsClient: userId
            };

            CommissionReportsRepository.getSalesman({
                data: { userId: rootGetters['user/user:id'] },
                columnOptions: { filters }
            })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data, filter }) => {
        state[key].storeData(data, filter);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
