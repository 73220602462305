<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-select
            :loading="loading"
            v-bind="$attrs"
            v-on="$listeners"
            v-model="innerValue"
            outlined
            :hide-details="hideHint"
            :error-messages="errors"
        >
            <template #selection="{ item }">
                <span v-if="$te(item.name)">
                    {{ $t(item.name) }}
                </span>
                <span v-else-if="item.name">
                    {{ item.name }}
                </span>
                <span v-else>
                    {{ item }}
                </span>
            </template>

            <template #item="{ item }">
                <span v-if="$te(item.name)">
                    {{ $t(item.name) }}
                </span>
                <span v-else-if="item.name">
                    {{ item.name }}
                </span>
                <span v-else>
                    {{ item }}
                </span>
            </template>

            <template #no-data>
                {{ $t('common.noData') }}
            </template>
        </v-select>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppSelectField',

        components: {
            ValidationProvider
        },

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },
            value: {
                required: false
            },
            hideHint: Boolean,
            loading: Boolean
        },

        data: () => ({
            innerValue: ''
        }),

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        mounted() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>

<style lang="scss">
    .v-text-field.v-select.error--text .v-select__slot .v-select__selections input::placeholder {
        color: colors.$color-warning;
    }
</style>
