<template>
    <app-container unlimited-width decreased-page-padding>
        <app-title>{{ $t('bo.products.categories.recommended') }}</app-title>

        <products-container :products="recommendedProducts" />

        <app-title class="mt-9">{{ $t('bo.products.categories.crypto') }}</app-title>

        <products-container :products="cryptoProducts" />

        <app-title class="mt-9">{{ $t('bo.products.categories.other') }}</app-title>

        <products-container :products="otherProducts" />
    </app-container>
</template>

<script>
    import ProductsContainer from '@/components/module-user/products/ProductsContainer';

    export default {
        name: 'Products',

        components: { ProductsContainer },

        data: () => ({
            recommendedProducts: [
                { id: 'EFK_LIQUIDITY__VALUE', category: 'crypto' },
                { id: 'EFK_PURCHASE', category: 'crypto' },
                { id: 'PARTICIPATION_4TRANS', category: 'other' }
            ],
            cryptoProducts: [
                { id: 'DIGI_RENT', category: 'crypto' },
                { id: 'EFK_STAKE', category: 'crypto' },
                { id: 'EFK_LIQUIDITY__VALUE', category: 'crypto' },
                { id: 'EFK_PURCHASE', category: 'crypto' }
            ],
            otherProducts: [{ id: 'PARTICIPATION_4TRANS', category: 'other' }]
        })
    };
</script>

<style scoped></style>
