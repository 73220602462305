<template>
    <app-container decreased-page-padding unlimited-width>
        <app-error v-if="filesError" fetching horizontal />

        <app-row v-else-if="!filesFetched">
            <app-registration-col whole-screen>
                <app-card>
                    <app-loader wrapper>{{ $t('table.loadingData') }}</app-loader>
                </app-card>
            </app-registration-col>
        </app-row>

        <document-category v-else v-for="category in categories" :key="category.id" :category="category" />
    </app-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    import DocumentCategory from '@/components/module-user/documents/DocumentCategory';

    export default {
        name: 'Documents',

        components: { DocumentCategory },

        computed: mapGetters('storage', ['categories', 'filesFetched', 'filesError']),

        mounted() {
            if (!this.filesFetched) {
                this.$store.dispatch('storage/files:fetch');
            }
        }
    };
</script>

<style scoped></style>
