<template>
    <app-container page-padding shadow>
        <app-row justify="center">
            <app-registration-col whole-screen>
                <div class="not-found-container">
                    <font-awesome-icon size="2x" :icon="['fad', icon]" v-color:primary />

                    <p class="not-found-container__title">
                        {{ title }}
                    </p>

                    <p class="not-found-container__description">
                        {{ description }}
                    </p>

                    <p
                        v-if="additionalDescription"
                        class="not-found-container__description not-found-container--additional"
                    >
                        {{ additionalDescription }}
                    </p>

                    <button-primary
                        class="not-found-container__button"
                        :loading="buttonLoading"
                        @click="$emit('button:click')"
                    >
                        {{ buttonTitle }}
                    </button-primary>
                </div>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    export default {
        name: 'BaseOverlay',

        props: ['icon', 'title', 'description', 'additionalDescription', 'buttonTitle', 'buttonLoading']
    };
</script>

<style scoped lang="scss">
    .not-found-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .not-found-container__title {
            @include textUtils.section-title;

            margin-top: spacing.$spacing-6;
        }

        .not-found-container__description {
            text-align: center;

            margin-top: spacing.$spacing-9;

            &.not-found-container--additional {
                margin-top: 0;
            }
        }

        .not-found-container__button {
            margin-top: spacing.$spacing-9;
        }
    }
</style>
