<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :label="$t('filters.fullname')" v-model="filters.fullname" />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        :label="$t('filters.referralId')"
                        v-model="filters.referralId"
                        :loading="!treeFetched"
                        :items="treeReferrals"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-text-field hide-hint :label="$t('filters.personalNumber')" v-model="filters.personalNumber" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :label="$t('filters.city')" v-model="filters.city" />
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthFrom')"
                        :menu.sync="calendar.monthFrom"
                        v-model="filters.monthFrom"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-date-picker
                        required-disabled
                        type="month"
                        :label="$t('filters.monthTo')"
                        :menu.sync="calendar.monthTo"
                        v-model="filters.monthTo"
                    />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
            multi-sort
            :options.sync="options"
        >
            <template v-slot:item.email="{ item }">
                {{ item.user.email }}
            </template>

            <template v-slot:item.city="{ item }">
                {{ getUserCity(item) }}
            </template>

            <template v-slot:item.name="{ item }">
                <router-link
                    class="detail"
                    :to="{
                        name: 'account:clients:detail',
                        params: { id: item.user.id, return: { name: 'account:clients' } }
                    }"
                >
                    {{ getFullName(item) }}
                </router-link>
            </template>

            <template v-slot:item.personalNumber="{ item }">
                {{ getPersonalNumber(item) }}
            </template>

            <template v-slot:item.referral="{ item }">
                <router-link
                    v-if="hasReferralAvailable(item)"
                    class="detail"
                    :to="{
                        name: 'account:coworkers',
                        params: { id: getReferralId(item), return: { name: 'account:clients' } }
                    }"
                >
                    {{ getReferralName(item) }}
                </router-link>

                <span v-else>{{ getReferralName(item) }}</span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination, sorting } from '@lb-world/core/public/components/tables/mixins';
    import { mapGetters } from 'vuex';

    import { createHeaderItem } from '@lb-world/core/public/components/tables/utils';

    import AppDatePicker from '@/components/ui/form/AppDatePicker';
    import AppSelectField from '@/components/ui/form/AppSelectField';
    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'ClientsTable',

        components: { AppTextField, AppSelectField, AppDatePicker },

        mixins: [core, pagination, filtering, sorting],

        data: () => ({
            calendar: {
                monthFrom: false,
                monthTo: false
            }
        }),

        computed: {
            ...mapGetters('clients', {
                treeFetched: 'tree:fetched',
                treeReferrals: 'tree:selectBox'
            }),

            userId() {
                return this.$store.getters['user/user:id'];
            },

            headers() {
                return [
                    createHeaderItem(this.$t('table.columns.name'), 'name', { sortable: true }),
                    createHeaderItem(this.$t('table.columns.personalNumber'), 'personalNumber'),
                    createHeaderItem(this.$t('table.columns.city'), 'city'),
                    createHeaderItem(this.$t('table.columns.referral'), 'referral', { sortable: true })
                ];
            }
        },

        methods: {
            hasReferralAvailable(item) {
                const referralId = this.getReferralId(item);

                const isReferralAvailable = this.treeReferrals.find(referral => referral.value === referralId);
                const isMyAccount = this.userId === referralId;

                return isReferralAvailable && !isMyAccount;
            },

            getUserCity(item) {
                return item?.user.address?.city ?? '-';
            },

            getPersonalNumber(item) {
                return item?.user?.personalInfo?.personalNumber ?? '-';
            },

            getReferralId(item) {
                return item.user?.referral?.id;
            },

            getReferralName(item) {
                const referral = item.user?.referral;

                if (referral) {
                    return referral.name + ' ' + referral.surname;
                } else {
                    return '-';
                }
            },

            getFullName(item) {
                const user = item.user;

                if (user) {
                    const name = user.personalInfo?.name ?? user.name;
                    const surname = user.personalInfo?.surname ?? user.surname;

                    return name + ' ' + surname;
                } else {
                    return '-';
                }
            }
        },

        mounted() {
            if (!this.treeFetched) {
                this.$store.dispatch('clients/tree:fetch');
            }
        }
    };
</script>

<style scoped></style>
