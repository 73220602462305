<template>
    <app-row>
        <app-registration-col whole-screen>
            <p class="popover-category">Proč?</p>

            <p class="mt-6">
                4Trans Factoring participace na úvěru („Participace 4T“) je produkt, který umožňuje ryze české
                společnosti 4Trans Factoring s.r.o. („4Trans“) reagovat na zvýšenou poptávku po jejích službách. Komu?
                Klienty 4Trans jsou společnosti a podnikatelé působící v logistice, zejména samotní dopravci.
            </p>

            <p class="popover-category mt-6">Co?</p>

            <p class="mt-6">
                4Trans poskytuje provozní financování formou faktoringu. Umožňuje tedy zkrátit splatnost faktur z
                maximálních 90 dní na 1 hodinu a zbavuje klienta starostí se správou a vymáháním pohledávek.
            </p>

            <p class="popover-category mt-6">Jak?</p>

            <p class="mt-6">
                4Trans při postoupení pohledávky okamžitě vyplatí dopravci typicky 70 % hodnoty faktury a přebírá její
                správu, upomínání a vymáhání. Po inkasu prostředků od odběratele dopravci odesílá zbylou část poníženou
                o poplatek za zprostředkování služby a úroky z poskytnuté zálohy.
            </p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'Introduction4Trans'
    };
</script>

<style scoped></style>
