import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import tabs from './tabs';
import auth from './auth';
import clients from '@/store/clients';
import commission from '@/store/commission';
import contracts from '@/store/contracts';
import storage from '@/store/storage';
import dashboard from '@/store/dashboard';
import reports from '@/store/reports';
import products from '@/store/products';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        tabs,
        auth,
        clients,
        storage,
        commission,
        contracts,
        dashboard,
        reports,
        products
    }
});
