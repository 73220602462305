<template>
    <app-container unlimited-width decreased-page-padding>
        <app-detail-header icon="box-alt" :on-back-pressed="onBackPressed">
            {{ $t('productTypes.' + productId) }}
        </app-detail-header>

        <app-row class="mt-6">
            <app-registration-col whole-screen class="pt-0">
                <v-expansion-panels v-model="openedPanels">
                    <app-expansion-panel
                        :title="$t('bo.products.detail.introduction')"
                        icon="align-left"
                        iconSet="fas"
                        v-if="productDetailSection.introduction.includes(productId)"
                    >
                        <component :is="'intro_' + productId" />
                    </app-expansion-panel>

                    <app-expansion-panel
                        :title="$t('bo.products.detail.advantages')"
                        icon="thumbs-up"
                        v-if="productDetailSection.advantages.includes(productId)"
                    >
                        <component :is="'advantage_' + productId" />
                    </app-expansion-panel>

                    <app-expansion-panel
                        :title="$t('bo.products.detail.parameters')"
                        icon="sliders-h"
                        v-if="productDetailSection.parameters.includes(productId)"
                    >
                        <component :is="'parameters_' + productId" />
                    </app-expansion-panel>

                    <app-expansion-panel
                        :title="$t('bo.products.detail.commission')"
                        icon="coin"
                        v-if="productDetailSection.commission.includes(productId)"
                    >
                        <component :is="'commission_' + productId" />
                    </app-expansion-panel>

                    <app-expansion-panel :title="$t('bo.products.detail.documents')" icon="file-alt">
                        <app-row>
                            <app-registration-col whole-screen>
                                <button-secondary
                                    inline
                                    icon="file-alt"
                                    @click="openProductDocument"
                                    :loading="downloadTeaserLoading"
                                >
                                    PDF Teaser
                                </button-secondary>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import {
        DOCUMENT_PRODUCT_SPECS_DIGI_RENT,
        DOCUMENT_PRODUCT_SPECS_4TRANS,
        DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY,
        DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE,
        DOCUMENT_PRODUCT_SPECS_EFK_STAKE
    } from '@/constants';

    import productDetailSection from '@/components/module-user/products/productsDetails/index';

    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    import Introduction4Trans from '@/components/module-user/products/productsDetails/introduction/Introduction4Trans';
    import IntroductionDigiRent from '@/components/module-user/products/productsDetails/introduction/IntroductionDigiRent';
    import IntroductionEfkLiquidity from '@/components/module-user/products/productsDetails/introduction/IntroductionEfkLiquidity';
    import IntroductionEfkStake from '@/components/module-user/products/productsDetails/introduction/IntroductionEfkStake';
    import IntroductionEfkPurchase from '@/components/module-user/products/productsDetails/introduction/IntroductionEfkPurchase';

    import Parameters4Trans from '@/components/module-user/products/productsDetails/parameters/Parameters4Trans';
    import ParametersDigiRent from '@/components/module-user/products/productsDetails/parameters/ParametersDigiRent';
    import ParametersEfkLiquidity from '@/components/module-user/products/productsDetails/parameters/ParametersEfkLiquidity';
    import ParametersEfkStake from '@/components/module-user/products/productsDetails/parameters/ParametersEfkStake';
    import ParametersEfkPurchase from '@/components/module-user/products/productsDetails/parameters/ParametersEfkPurchase';

    import Advantage4Trans from '@/components/module-user/products/productsDetails/advantages/Advantage4Trans';
    import AdvantageEfkPurchase from '@/components/module-user/products/productsDetails/advantages/AdvantageEfkPurchase';
    import AdvantageEfkStake from '@/components/module-user/products/productsDetails/advantages/AdvantageEfkStake';
    import AdvantageDigiRent from '@/components/module-user/products/productsDetails/advantages/AdvantageDigiRent';
    import AdvantageEfkLiquidity from '@/components/module-user/products/productsDetails/advantages/AdvantageEfkLiquidity';

    import CommissionEfkLiquidity from '@/components/module-user/products/productsDetails/commission/CommissionEfkLiquidity';
    import Commission4Trans from '@/components/module-user/products/productsDetails/commission/Commission4Trans';
    import CommissionDigiRent from '@/components/module-user/products/productsDetails/commission/CommissionDigiRent';
    import CommissionEfkStake from '@/components/module-user/products/productsDetails/commission/CommissionEfkStake';
    import AppDetailHeader from '@lb-world/core/public/components/utils/AppDetailHeader';

    export default {
        name: 'ProductDetail',

        components: {
            AppDetailHeader,
            AppExpansionPanel,

            intro_DIGI_RENT: IntroductionDigiRent,
            intro_PARTICIPATION_4TRANS: Introduction4Trans,
            intro_EFK_LIQUIDITY__VALUE: IntroductionEfkLiquidity,
            intro_EFK_STAKE: IntroductionEfkStake,
            intro_EFK_PURCHASE: IntroductionEfkPurchase,

            advantage_DIGI_RENT: AdvantageDigiRent,
            advantage_EFK_STAKE: AdvantageEfkStake,
            advantage_EFK_PURCHASE: AdvantageEfkPurchase,
            advantage_EFK_LIQUIDITY__VALUE: AdvantageEfkLiquidity,
            advantage_PARTICIPATION_4TRANS: Advantage4Trans,

            parameters_DIGI_RENT: ParametersDigiRent,
            parameters_PARTICIPATION_4TRANS: Parameters4Trans,
            parameters_EFK_LIQUIDITY__VALUE: ParametersEfkLiquidity,
            parameters_EFK_STAKE: ParametersEfkStake,
            parameters_EFK_PURCHASE: ParametersEfkPurchase,

            commission_EFK_LIQUIDITY__VALUE: CommissionEfkLiquidity,
            commission_PARTICIPATION_4TRANS: Commission4Trans,
            commission_DIGI_RENT: CommissionDigiRent,
            commission_EFK_STAKE: CommissionEfkStake
        },

        data: () => ({
            openedPanels: [],

            downloadTeaserLoading: false,

            productId: null,

            productDetailSection
        }),

        watch: {
            $route() {
                this.productId = this.$route.params?.id;
            }
        },

        methods: {
            onBackPressed() {
                const returnRoute = this.$route.params?.return;

                this.$router.push(returnRoute ? returnRoute : { name: 'account:products' });
            },

            openProductDocument() {
                this.downloadTeaserLoading = true;

                let documentId;

                switch (this.productId) {
                    case 'DIGI_RENT':
                        documentId = DOCUMENT_PRODUCT_SPECS_DIGI_RENT;
                        break;

                    case 'EFK_STAKE':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_STAKE;
                        break;

                    case 'EFK_LIQUIDITY__VALUE':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY;
                        break;

                    case 'EFK_PURCHASE':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE;
                        break;

                    case 'PARTICIPATION_4TRANS':
                        documentId = DOCUMENT_PRODUCT_SPECS_4TRANS;
                        break;
                }

                const windowRef = window.open();

                this.$store
                    .dispatch('products/download', documentId)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.downloadTeaserLoading = false;
                    });
            }
        },

        mounted() {
            this.productId = this.$route.params?.id;
        }
    };
</script>

<style scoped lang="scss"></style>
