<template>
    <app-row>
        <app-registration-col whole-screen>
            <ul>
                <li>Jednoduše spojuje příležitosti</li>
                <li>Na burze i mimo ni</li>
                <li>Bez registrace na burzách</li>
                <li>V češtině a v korunách</li>
                <li>Roboty zefektivněný nákup</li>
                <li>Předčasné ukončení produktu</li>
                <li>Flexibilní parametry</li>
                <li>Poplatek postupně</li>
            </ul>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'AdvantageEfkPurchase'
    };
</script>

<style scoped></style>
